import StyleStyle from 'ol/style/Style'
import StyleStroke from 'ol/style/Stroke'
import StyleFill from 'ol/style/Fill'
import StyleText from 'ol/style/Text'
import OlStyleIcon from 'ol/style/Icon'
import OlGeomLineString from 'ol/geom/LineString'
import OlGeomMultiPoint from 'ol/geom/MultiPoint'
import OlStyleCircle from 'ol/style/Circle'
import { mapIcons } from '../../constants/Geospatial'

const drawnLines = []
const drawnPoints = []
const fieldCoordinates = []

export function createDrawStyleFunction(feature) {
  if (feature.getGeometry().getType() === 'Polygon') {
    fieldCoordinates.length = 0
    drawnLines.length = 0
    const coordsLength = feature.getGeometry().getCoordinates()[0].length
    const tempFeatureCoords = feature
      .getGeometry()
      .getCoordinates()[0]
      .slice(0)
    const tempPointCoords = feature
      .getGeometry()
      .getCoordinates()[0]
      .slice(0)
    for (let i = 0; i < coordsLength - 1; i += 1) {
      let tempPoint = tempPointCoords.splice(0, 1)
      tempPoint = [].concat(...tempPoint)
      if (tempPoint.length > 0) {
        drawnPoints.push(tempPoint)
      }
      tempPoint = []
    }
    for (let i = 0; i < coordsLength - 1; i += 1) {
      const tempPair = tempFeatureCoords[i]
      fieldCoordinates.push(tempPair)
    }
    for (let i = 0; i < fieldCoordinates.length - 1; i += 1) {
      const segment = new OlGeomLineString([
        fieldCoordinates[i],
        fieldCoordinates[i + 1]
      ])
      drawnLines.push(segment)
    }
  }

  const color = [0, 153, 255, 1]
  const white = [255, 255, 255, 1]
  return [
    new StyleStyle({
      fill: new StyleFill({
        color: [255, 255, 255, 0.5]
      })
    }),
    new StyleStyle({
      stroke: new StyleStroke({
        color: white,
        width: 5
      }),
      geometry: feat => {
        let lineStringGeom
        if (feat.getGeometry().getType() === 'Polygon') {
          const loopLength = fieldCoordinates.length - 1
          const lineStringCoords = []
          for (let i = 0; i < loopLength; i += 1) {
            lineStringCoords.push(fieldCoordinates[i])
          }
          lineStringGeom = new OlGeomLineString(lineStringCoords)
        }
        return lineStringGeom
      }
    }),
    new StyleStyle({
      stroke: new StyleStroke({
        color,
        width: 3
      }),
      geometry: feat => {
        let lineStringGeom
        if (feat.getGeometry().getType() === 'Polygon') {
          const loopLength = fieldCoordinates.length - 1
          const lineStringCoords = []
          for (let i = 0; i < loopLength; i += 1) {
            lineStringCoords.push(fieldCoordinates[i])
          }
          lineStringGeom = new OlGeomLineString(lineStringCoords)
        }
        return lineStringGeom
      }
    }),
    new StyleStyle({
      image: new OlStyleCircle({
        radius: 6,
        fill: new StyleFill({
          color
        }),
        stroke: new StyleStroke({
          color: white,
          width: 1.5
        })
      }),
      geometry: feat => {
        const coordinates = feat.getGeometry().getCoordinates()
        return new OlGeomMultiPoint(coordinates)
      }
    }),
    new StyleStyle({
      image: new OlStyleCircle({
        radius: 6,
        fill: new StyleFill({
          color
        }),
        stroke: new StyleStroke({
          color: white,
          width: 1.5
        })
      })
    })
  ]
}

export function deletePointStyle() {
  const style = new StyleStyle({
    image: new OlStyleCircle({
      radius: 18,
      fill: new StyleFill({
        color: [0, 153, 255, 1]
      }),
      stroke: new StyleStroke({ color: '#FFF', width: 1.5 })
    }),
    text: new StyleText({
      text: '×',
      textAlign: 'center',
      font: '34px HelveticaBold',
      fill: new StyleFill({ color: '#FFF' })
    }),
    zIndex: 1
  })
  return style
}
export function firstPointStyle() {
  const style = new StyleStyle({
    image: new OlStyleIcon({
      src: mapIcons.completeDrawing,
      crossOrigin: 'Anonymous',
      scale: 0.8
    }),
    zIndex: 1
  })
  return style
}
