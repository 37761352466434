import * as ActionTypes from '../actions/actionTypes'
import {
  BASEMAP_OPTIONS,
  AERIS_WEATHER_VARIABLES,
  AERIS_WEATHER_TIMEFRAMES,
  NATIONAL_RAINFALL_DIVISIONS,
  NATIONAL_RAINFALL_TIMEFRAMES,
  AERIS_VARIABLE_LOOKUP
} from '../../constants/Geospatial'

const initialState = {
  basemap: BASEMAP_OPTIONS[0].value,
  isRadarLayersOn: false,
  radarTimeframe: AERIS_WEATHER_TIMEFRAMES[0].value,
  radarVariable: AERIS_WEATHER_VARIABLES[0].value,
  isRainfallLayersOn: false,
  rainfallDivision: NATIONAL_RAINFALL_DIVISIONS[0].value,
  rainfallTimeframe: NATIONAL_RAINFALL_TIMEFRAMES[0].value,
  aerisVariable: AERIS_WEATHER_VARIABLES[0].value,
  popupFeature: null,
  fieldCoordinates: null
}

const geospatial = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_BASEMAP:
      return {
        ...state,
        basemap: action.payload.data
      }
    case ActionTypes.SET_RADAR_TIMEFRAME:
      return {
        ...state,
        radarTimeframe: action.payload.data,
        aerisVariable:
          AERIS_VARIABLE_LOOKUP[state.radarVariable][action.payload.data]
      }
    case ActionTypes.SET_RADAR_VARIABLE:
      return {
        ...state,
        radarVariable: action.payload.data,
        aerisVariable:
          AERIS_VARIABLE_LOOKUP[action.payload.data][state.radarTimeframe]
      }
    case ActionTypes.TOGGLE_RADAR_LAYER:
      return {
        ...state,
        isRadarLayersOn: !state.isRadarLayersOn,
        isRainfallLayersOn: false
      }
    case ActionTypes.SET_RAINFALL_DIVISION:
      return {
        ...state,
        rainfallDivision: action.payload.data
      }
    case ActionTypes.SET_RAINFALL_TIMEFRAME:
      return {
        ...state,
        rainfallTimeframe: action.payload.data
      }
    case ActionTypes.TOGGLE_RAINFALL_LAYER:
      return {
        ...state,
        isRainfallLayersOn: !state.isRainfallLayersOn,
        isRadarLayersOn: false
      }
    case ActionTypes.SET_POPUP_DATA:
      return {
        ...state,
        popupFeature: action.payload.feature
      }
    case ActionTypes.CLEAR_POPUP_DATA:
      return {
        ...state,
        popupFeature: null
      }
    case ActionTypes.SAVE_FIELD_COORDINATES:
      return {
        ...state,
        fieldCoordinates: action.payload
      }
    default:
      return state
  }
}

export default geospatial
