// This layer's responsibility is combining Formik with MUI/UET
// Note: name prop may be using dot-notation, so it's important to always
//       use _get when retrieving the value. Avoid this: values[name]

import React, { useCallback, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import {
  useField,
  useFormikContext,
  ErrorMessage as FormikErrorMessage
} from 'formik'
import _get from 'lodash/get'
import _isNil from 'lodash/isNil'
import _find from 'lodash/find'
import Box from '@nutrien/uet-react/Box'
import NutrienRadio from '@nutrien/uet-react/Radio'
import NutrienCheckbox from '@nutrien/uet-react/Checkbox'
import NutrienSelect from '@nutrien/uet-react/Select'
import NutrienTextField from '@nutrien/uet-react/TextField'
import NutrienSwitch from '@nutrien/uet-react/Switch'
import FormControlLabel from '@nutrien/uet-react/FormControlLabel'
import Typography from '@nutrien/uet-react/Typography'
import KeyboardDatePicker from '@nutrien/uet-react/KeyboardDatePicker'
import DateRangeIcon from '@nutrien/uet-react/icons/DateRange'
import MUIAutocomplete from '@material-ui/lab/Autocomplete'
import SearchIcon from '@nutrien/uet-react/icons/Search'
import InputAdornment from '@nutrien/uet-react/InputAdornment'

import { isValid, castToUTC, castToLocal, startOfDay } from 'helpers/date'

import { generateIdFromName } from './utils'

export const FIELD_WIDTH_SHORT = '88px'
export const FIELD_WIDTH_LONG = '288px'
export const FIELD_HEIGHT = '40px'

const connectStyle = ({ connect }) =>
  !connect
    ? ''
    : css`
  fieldset {
    border-top-${connect}-radius: 0;
    border-bottom-${connect}-radius: 0;
    ${
      connect !== 'right'
        ? ''
        : css`
            border-right: 0;
          `
    }
  }
`

/**
 * @deprecated
 * use src/components/Radio.jsx
 */
export const Radio = props => {
  const { setFieldValue } = useFormikContext()
  const [field] = useField(props.name)
  const handleChange = useCallback(() => {
    // Ignore event.target.value, because
    // it forces values into strings
    setFieldValue(props.name, props.value)
  }, [props.name, props.value, setFieldValue])
  return (
    <FormControlLabel
      {...field}
      id={generateIdFromName(props.name)}
      {...props}
      control={<NutrienRadio color="primary" />}
      label={
        typeof props.label === 'string' ? (
          <Box py={1}>{props.label}</Box>
        ) : (
          props.label
        )
      }
      onChange={handleChange}
    />
  )
}

/**
 * @deprecated
 * use src/components/Checkbox.jsx
 */
export const Checkbox = ({ dataTestId, indeterminate, ...props }) => {
  const [field] = useField(props.name)
  return (
    <FormControlLabel
      {...field}
      data-testid={dataTestId}
      id={props.id || generateIdFromName(props.name)}
      {...props}
      control={
        <NutrienCheckbox color="primary" indeterminate={indeterminate} />
      }
    />
  )
}

/**
 * @deprecated
 * use src/components/ValueToggleCheckbox.jsx
 */
export const ValueToggleCheckbox = ({
  dataTestId,
  indeterminate,
  checkedValue,
  uncheckedValue,
  onChange,
  preventDefault,
  ...props
}) => {
  const [field, , helpers] = useField(props.name)

  return (
    <FormControlLabel
      {...field}
      checked={field.value === checkedValue}
      data-testid={dataTestId}
      id={props.id || generateIdFromName(props.name)}
      onChange={event => {
        if (!preventDefault) {
          helpers.setValue(event.target.checked ? checkedValue : uncheckedValue)
        }

        if (onChange) {
          onChange(event)
        }
      }}
      {...props}
      control={
        <NutrienCheckbox color="primary" indeterminate={indeterminate} />
      }
    />
  )
}

export const Select = styled(
  ({ isCustomRender, customRenderOptions, ...props }) => {
    const [field] = useField(props.name)
    const { errors, touched } = useFormikContext()
    const hasError = !!_get(errors, props.name)
    const isTouched = !!_get(touched, props.name)
    return (
      <NutrienSelect
        error={hasError && isTouched}
        {...field}
        id={generateIdFromName(props.name)}
        {...(isCustomRender && {
          renderValue: value => {
            const option = _find(customRenderOptions, { value })
            return option ? option.label : value
          }
        })}
        {...props}
      />
    )
  }
)`
  width: ${({ width }) => width || FIELD_WIDTH_LONG};
  ${connectStyle}
  /* Fix for grey highlight leaking outside when in focus */
  .MuiSelect-root {
    height: ${FIELD_HEIGHT};
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
`

const resolveInputWidth = ({ width, type, multiline }) => {
  if (!_isNil(width)) {
    return width
  }
  if (type === 'number') {
    return FIELD_WIDTH_SHORT
  }
  if (multiline) {
    return '100%'
  }
  return FIELD_WIDTH_LONG
}
export const StyledInput = styled.div`
  width: ${resolveInputWidth};
  .MuiInputBase-root {
    height: ${({ multiline, multilinePlaceholder }) =>
      multiline && !multilinePlaceholder ? 'auto' : FIELD_HEIGHT};
  }
  .MuiTextField-root {
    margin: 0;
  }
  ${connectStyle}
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  ${({ multilinePlaceholder }) =>
    !multilinePlaceholder
      ? ''
      : css`
          textarea {
            resize: none;
            line-height: 0.875em;
          }
        `}
`
const useMultilinePlaceholder = props => {
  const { values, setFieldValue, validateField } = useFormikContext()
  const currentValue = _get(values, props.name)
  const isFalsy = _isNil(currentValue) || currentValue === ''
  const ref = useRef(null)
  const inputIsFocused =
    ref.current && ref.current.querySelector('input') === document.activeElement
  const multilinePlaceholder =
    (props.placeholder || '').includes('\n') &&
    isFalsy &&
    !inputIsFocused &&
    !props.multiline

  const [carryOver, setCarryOver] = useState('')
  const [caretPosition, setCaretPosition] = useState(null)

  // When input type is supposed to be number and user types in
  // characters like `-` or `.` (or their localized equivalent)
  // to the textarea placeholder, we want to carry that over
  // once the actual input mounts
  // In case of non-numeric inputs, we still want to carry
  // over the caret position and the first character
  const trackPrenumericInput = useCallback(
    event => {
      setCarryOver(event.target.value)
      setCaretPosition(event.target.selectionStart)
      const parsed = parseFloat(event.target.value)
      const value =
        props.type === 'number' && !isNaN(parsed) ? parsed : event.target.value
      const isCompleteNumber =
        (parsed + '').length === event.target.value.length
      if (props.type === 'number') {
        if (typeof value !== 'number' || !isCompleteNumber) {
          setCarryOver(event.target.value)
          return
        }
        setCarryOver(value)
        setFieldValue(props.name, value)
        return
      }
      setFieldValue(props.name, value)
    },
    [props.name, props.type, setFieldValue]
  )

  // Once DOM nodes swap from textarea to input
  // We need to carry over all the characters
  const applyPrenumericInput = useCallback(
    newRef => {
      ref.current = newRef
      if (!newRef) return
      const input = newRef.querySelector('input')
      if (input && carryOver) {
        input.value = carryOver
        input.focus()
        input.setSelectionRange(caretPosition, caretPosition)
        setCaretPosition(null)
        setCarryOver('')
      }
    },
    [caretPosition, carryOver]
  )

  const flushPrenumericInput = useCallback(() => {
    if (carryOver) {
      const parsed = isNaN(parseFloat(carryOver)) ? '' : parseFloat(carryOver)
      const value = props.type === 'number' ? parsed : carryOver
      setCarryOver('')
      setFieldValue(props.name, value)
      setTimeout(() => validateField(props.name))
    }
  }, [carryOver, props.name, props.type, setFieldValue, validateField])

  return {
    applyPrenumericInput,
    carryOver,
    flushPrenumericInput,
    multilinePlaceholder,
    trackPrenumericInput
  }
}

export const TextField = props => {
  const { errors, touched, setFieldValue } = useFormikContext()
  const [field] = useField(props.name)
  const hasError = !!_get(errors, props.name)
  const isTouched = !!_get(touched, props.name)
  const rows = isNaN(props.rows) ? 8 : props.rows
  const emptyStringToNull = props?.coerceStringToNull

  // Converts incomplete numbers, e.g. `.5` to `0.5``
  const normalizeNumericInput = useCallback(
    event => {
      if (props.type === 'number') {
        const value = parseFloat(event.target.value)
        // used for form validation so clearing an input does not set value of empty string
        if (emptyStringToNull && isNaN(value)) {
          setFieldValue(event.target.name, null)
        }
        if (!isNaN(value)) {
          event.target.value = ''
          event.target.value = value
        }
      }
      const handler = props.onBlur || field.onBlur
      if (handler) return handler(event)
    },
    [emptyStringToNull, field.onBlur, props.onBlur, props.type, setFieldValue]
  )

  const {
    multilinePlaceholder,
    trackPrenumericInput,
    applyPrenumericInput,
    flushPrenumericInput,
    carryOver
  } = useMultilinePlaceholder(props)

  return (
    <StyledInput
      connect={props.connect}
      multiline={props.multiline}
      multilinePlaceholder={multilinePlaceholder}
      type={props.type}
      width={props.width}>
      {multilinePlaceholder && (
        <NutrienTextField
          error={hasError && isTouched}
          {...field}
          id={generateIdFromName(props.name)}
          inputProps={{ step: 'any' }}
          onBlur={flushPrenumericInput}
          onChange={trackPrenumericInput}
          rows={props.multiline ? rows : undefined}
          {...props}
          multiline={props.multiline || multilinePlaceholder}
          value={carryOver}
        />
      )}
      {!multilinePlaceholder && (
        <NutrienTextField
          error={hasError && isTouched}
          ref={applyPrenumericInput}
          {...field}
          id={generateIdFromName(props.name)}
          inputProps={{ step: 'any' }}
          rows={props.multiline ? rows : undefined}
          {...props}
          onBlur={normalizeNumericInput}
          type={carryOver ? 'text' : props.type}
        />
      )}
    </StyledInput>
  )
}

export const Switch = props => {
  const { values } = useFormikContext()
  const [field] = useField(props.name)
  const value = !!_get(values, props.name)
  return (
    <NutrienSwitch
      {...field}
      checked={value}
      id={generateIdFromName(props.name)}
      value={value}
      {...props}
      color="primary"
    />
  )
}

export const DatePicker = styled(
  ({ returnInvalidDate, shouldDisableDate, ...props }) => {
    const { values, setFieldValue, errors, touched } = useFormikContext()
    const [field] = useField(props.name)
    // Ideally we could use ref, but currently it seems
    // date picker does not forward the ref correctly
    const [incompleteDate, setIncompleteDate] = useState()
    const { onChange } = props
    const handleChange = useCallback(
      newDate => {
        setIncompleteDate(!isValid(newDate) ? newDate : undefined)
        if (`${props.name}EmptyFlag` in values) {
          setFieldValue(`${props.name}EmptyFlag`, false)
        }
        // To handle optional field validation in case of empty
        if (`${props.name}EmptyFlag` in values && newDate === null) {
          setFieldValue(`${props.name}EmptyFlag`, true)
          setFieldValue(props.name, null)
          return
        }
        if (!isValid(newDate)) {
          // Outside of this component we want to avoid
          // operating on null (1970 bug) and Invalid Date
          if (returnInvalidDate) setFieldValue(props.name, newDate)
          else setFieldValue(props.name, undefined)
          return
        }
        const isoDate = castToUTC(newDate)
        const sodIsoDate = startOfDay(isoDate)
        setFieldValue(props.name, sodIsoDate)
        if (onChange) {
          onChange(sodIsoDate)
        }
      },
      [setFieldValue, returnInvalidDate, props.name, onChange, values]
    )
    const hasError = !!_get(errors, props.name)
    const isTouched = !!_get(touched, props.name)
    // Picker MUST be fed with null instead of undefined
    // Whereas Date constructor MUST be fed with undefined instead of null (1970 bug)
    // We must also forward Invalid Date to the picker, to avoid bugs related to manual date edit
    const value = _get(values, props.name) || null
    const pickerValue = incompleteDate || (value && castToLocal(value))
    return (
      <Box width={props?.width ?? FIELD_WIDTH_LONG}>
        <KeyboardDatePicker
          {...field}
          autoOk
          data-testid="date-picker"
          error={hasError && isTouched}
          format="MM/dd/yyyy"
          id={generateIdFromName(props.name)}
          inputProps={{ 'data-testid': 'date-picker-input' }}
          keyboardIcon={<DateRangeIcon />}
          value={pickerValue}
          variant="inline"
          {...props}
          shouldDisableDate={
            shouldDisableDate && (date => shouldDisableDate(date, values))
          }
          // FIXME: we should align other onChange props in this file to
          // also be a notifier rather than a replacement mutator
          onChange={handleChange}
          type={undefined}
        />
      </Box>
    )
  }
)`
  height: ${FIELD_HEIGHT};
`

const ErrorMessageDisplay = ({ id, children, 'data-testid': dataTestId }) => (
  <Typography className="field-error" color="error" data-testid={dataTestId}>
    {children}
  </Typography>
)
export const ErrorMessage = props => {
  return <FormikErrorMessage component={ErrorMessageDisplay} {...props} />
}

const StyledMUIAutocomplete = styled(MUIAutocomplete)`
  .MuiAutocomplete-popupIndicator {
    display: none;
  }
  .MuiInputAdornment-positionStart {
    margin-right: 0;
  }
`

export const Autocomplete = ({
  name,
  'data-testid': dataTestId,
  type,
  disabled,
  connect,
  options,
  freeSolo,
  width,
  onChange,
  getOptionLabel
}) => {
  const { errors, touched, setFieldValue } = useFormikContext()
  const [field] = useField(name)
  const hasError = !!_get(errors, name)
  const isTouched = !!_get(touched, name)
  const handleChange = useCallback(
    (event, newValue) => {
      setFieldValue(name, newValue)
      if (onChange) onChange(newValue)
    },
    [name, setFieldValue, onChange]
  )
  const handleInputChange = useCallback(
    event => {
      if (event && freeSolo) {
        setFieldValue(name, event.target.value)
      }
    },
    [freeSolo, setFieldValue, name]
  )
  // Autocomplete is controlled by formik, however it is initially undefined
  // which causes a warning about changing from uncontrolled to controlled.
  if (field.value === undefined) field.value = ''
  return (
    <StyledInput connect={connect} type={type} width={width}>
      <StyledMUIAutocomplete
        {...field}
        data-testid={dataTestId}
        disabled={disabled}
        freeSolo={freeSolo}
        id={generateIdFromName(name)}
        onChange={handleChange}
        onInputChange={handleInputChange}
        options={options}
        renderInput={params => (
          <NutrienTextField
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            error={hasError && isTouched}
            id={generateIdFromName(name)}
            variant="outlined"
          />
        )}
        {...(!!getOptionLabel && { getOptionLabel })}
      />
    </StyledInput>
  )
}
