export const SET_SELECTED_ID = 'SET_SELECTED_ID'
export const SET_OPEN_ENTITIES = 'SET_OPEN_ENTITIES'
export const SET_SEARCH_STRING = 'SET_SEARCH_STRING'
export const TOGGLE_FARM_TREE = 'TOGGLE_FARM_TREE'
export const TOGGLE_EDIT = 'TOGGLE_EDIT'
export const TOGGLE_EDIT_DIALOG = 'TOGGLE_EDIT_DIALOG'

export const REQUEST_ENTITIES = 'REQUEST_ENTITIES'
export const RECEIVE_ENTITIES_SUCCESS = 'RECEIVE_ENTITIES_SUCCESS'
export const RECEIVE_ENTITIES_ERROR = 'RECEIVE_ENTITIES_ERROR'

export const REQUEST_CREATE_ENTITY = 'REQUEST_CREATE_ENTITY'
export const RECEIVE_CREATE_ENTITY_SUCCESS = 'RECEIVE_CREATE_ENTITY_SUCCESS'
export const RECEIVE_CREATE_ENTITY_ERROR = 'RECEIVE_CREATE_ENTITY_ERROR'

export const REQUEST_UPDATE_ENTITY = 'REQUEST_UPDATE_ENTITY'
export const RECEIVE_UPDATE_ENTITY_SUCCESS = 'RECEIVE_UPDATE_ENTITY_SUCCESS'
export const RECEIVE_UPDATE_ENTITY_ERROR = 'RECEIVE_UPDATE_ENTITY_ERROR'

export const REQUEST_DELETE_ENTITY = 'REQUEST_DELETE_ENTITY'
export const RECEIVE_DELETE_ENTITY_SUCCESS = 'RECEIVE_DELETE_ENTITY_SUCCESS'
export const RECEIVE_DELETE_ENTITY_ERROR = 'RECEIVE_DELETE_ENTITY_ERROR'
