import { createSelector } from 'reselect'
import getFlattenedEntities from './getFlattenedEntities'
import getActiveEntity from './getActiveEntity'

export const getChildren = (activeEntity, flattenedEntities) => {
  const entityChildren = activeEntity.childrenUUIDs || []
  const childrenArray = entityChildren
    .filter(uuid => flattenedEntities[uuid])
    .map(uuid => {
      const entityChild = flattenedEntities[uuid].childrenUUIDs
        ? getChildren(flattenedEntities[uuid], flattenedEntities)
        : flattenedEntities[uuid]
      return entityChild
    })
  return {
    type: activeEntity.type,
    name: activeEntity.name,
    uuid: activeEntity.uuid,
    owner: activeEntity.owner,
    children: childrenArray
  }
}

const getNestedActiveEntityChildren = createSelector(
  [getFlattenedEntities, getActiveEntity],
  (flattenedEntities, activeEntity) => {
    if (activeEntity && flattenedEntities) {
      return getChildren(activeEntity, flattenedEntities)
    }
    return null
  }
)

export default getNestedActiveEntityChildren
