import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import FieldThumbnail from 'components/GeoSpatial/components/FieldThumbnail'

import th from 'theme/helpers'

const BoundaryPreview = styled.div`
  width: 100%;
  background-color: ${th.palette('grey.50')};
  padding: ${th.spacing(1)};
  box-sizing: border-box;
  text-align: center;
`

const NutrienFieldThumbnail = ({ entity }) => {
  const geom = entity.getGeometry()
  const props = {
    fieldGeometry: {
      type: geom.getType(),
      coordinates: geom.getCoordinates()
    },
    extent: geom.getExtent()
  }

  return (
    <BoundaryPreview>
      <FieldThumbnail {...props} />
    </BoundaryPreview>
  )
}

FieldThumbnail.propTypes = {
  entity: PropTypes.object.isRequired
}

export default React.memo(NutrienFieldThumbnail)
