import { createSelector } from 'reselect'
import get from 'lodash/get'
import getFlattenedEntities from './getFlattenedEntities'
import getActiveId from './getActiveId'

export function getEntityParent(entities, uuid) {
  return entities
    ? Object.keys(entities).find(e =>
        get(entities, [e, 'childrenUUIDs'], []).includes(uuid)
      )
    : null
}

export function getEntityParents(entities, uuid, parents) {
  const parentUUID = getEntityParent(entities, uuid)
  if (parentUUID) {
    parents.unshift(parentUUID)
    return getEntityParents(entities, parentUUID, parents)
  }
  return parents
}

export const getActiveEntityParents = createSelector(
  [getFlattenedEntities, getActiveId],
  (entities, uuid) => {
    const parents = getEntityParents(entities, uuid, [])
    return parents
  }
)

export default getActiveEntityParents
