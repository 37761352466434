import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState, useMemo, useRef } from 'react'
import useMediaQuery from '@nutrien/uet-react/useMediaQuery'
import Box from '@nutrien/uet-react/Box'
import Drawer from '@nutrien/uet-react/Drawer'
import _uniqBy from 'lodash/uniqBy'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'
import { useManualQuery } from 'graphql-hooks'
import {
  ENTITY_TYPE_ACCOUNT,
  GET_CAMPAIGNS_ENROLLMENT_NAVIGATION_TREE,
  UUID_MY_ACCOUNT as UUID_ALL_ORGANIZATIONS
} from 'const'

import { makeEntityTrees, cleanFarmTreeData } from 'helpers'
import useFarmTreeSortFilter from 'hooks/useFarmTreeSortFilter'
import useToggle from 'hooks/useToggle'
import useJohnDeereCreds from 'hooks/useJohnDeereCreds'
import usePanel from 'hooks/usePanel'
import AddFieldWizard from 'components/AddFieldWizard'
import FarmTreeFilter from 'components/FarmTreeFilter'
import createUrl from 'helpers/createUrl'
import EditEntityDialog from 'components/EditEntityDialog'
import {
  FarmTreeFiltersContext,
  SHOW_ALL
} from 'components/FarmTreeFiltersProvider'

import history from '../../history'
import FarmTreeContent from './components/FarmTreeContent'
import FarmTreeControls from './components/FarmTreeControls'
import EntityBox from './components/EntityBox'

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    backgroundColor: theme.palette.grey[50],
    minHeight: '100%'
  }
}))

const FarmTree = ({ flattenedEntities, previousSelectedId, selectedId }) => {
  const selectedEntity = useMemo(
    () =>
      flattenedEntities[selectedId] === undefined
        ? Object.values(flattenedEntities)[0]
        : flattenedEntities[selectedId],
    [flattenedEntities, selectedId]
  )

  const { selectedFilter, membershipOptions } = useContext(
    FarmTreeFiltersContext
  )

  const jdCreds = useJohnDeereCreds()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const [isSelecting, toggleIsSelecting] = useToggle(false)
  const [isEditing, toggleIsEditing] = useToggle(false)
  const { isOpen: showAddField, togglePanel: toggleAddField } = usePanel(
    'add_field_options'
  )

  const [
    fetchEnrolledFieldsInCampaign,
    { data: enrolledFieldsInCampaign, loading: enrolledFieldsDataLoading }
  ] = useManualQuery(GET_CAMPAIGNS_ENROLLMENT_NAVIGATION_TREE, {
    useCache: false
  })

  useEffect(() => {
    if (selectedFilter.filterByProject !== SHOW_ALL) {
      const memberships = membershipOptions()
      const campaignMembership = memberships.find(
        ({ value }) => value === selectedFilter?.filterByProject
      )
      fetchEnrolledFieldsInCampaign({
        variables: { membershipUuid: campaignMembership.membershipUuid }
      })
    }

    // we only want this effect to be triggered when selectedFilter changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter])

  const enrolledFields =
    enrolledFieldsInCampaign?.getEnrolledFieldsForCampaign?.flatMap(
      field => field.uuid
    ) ?? []

  const [searchString, setSearchString] = useState('')
  const filteredEntities = useFarmTreeSortFilter(
    cleanFarmTreeData(flattenedEntities),
    searchString,
    enrolledFields
  )

  const entityTree = useMemo(
    () => _uniqBy(makeEntityTrees(filteredEntities), entity => entity.uuid),
    [filteredEntities]
  )

  // FIXME: Intercept selection until we patch up the tree to accept a handler
  useEffect(() => {
    const selectionChanged =
      previousSelectedId && selectedId && selectedId !== previousSelectedId
    if (isSelecting && selectionChanged) {
      toggleIsSelecting(false)
    }
  }, [previousSelectedId, selectedId, isSelecting, toggleIsSelecting])

  // Check if we've just come back from John Deere,
  // if so, open the Add Field Wizard for further processing
  useEffect(() => {
    if (jdCreds && !showAddField) toggleAddField()
  }, [jdCreds, showAddField, toggleAddField])

  const { drawerPaper } = useStyles()

  const ref = useRef()

  const farmTreeElement = (
    <Box p={2} pb={10} overflow="hidden" data-testid="farm-tree">
      <FarmTreeControls
        entity={selectedEntity}
        handleEdit={toggleIsEditing}
        handleAdd={toggleAddField}
        handleClose={toggleIsSelecting}
      />
      <FarmTreeContent
        selectedId={selectedId}
        entities={entityTree}
        setSearchString={setSearchString}
        searchString={searchString}
        enrolledFieldsDataLoading={enrolledFieldsDataLoading}
        ref={ref}
      />
    </Box>
  )

  return (
    <>
      <EditEntityDialog
        key={selectedEntity.uuid}
        entity={selectedEntity}
        isOpen={isEditing}
        onClose={toggleIsEditing}
        flattenedEntities={flattenedEntities}
        navigateOnDelete={() =>
          history.replace(
            createUrl(
              history.location,
              {
                farmItemLevel: ENTITY_TYPE_ACCOUNT,
                farmItemId: UUID_ALL_ORGANIZATIONS
              },
              { clearPageParams: true, clearTrackedParams: true }
            )
          )
        }
      />
      {showAddField && (
        <AddFieldWizard
          onClose={toggleAddField}
          selectedEntity={selectedEntity}
        />
      )}
      {!isMobile && (
        <Box overflow="hidden">
          {farmTreeElement}
          <FarmTreeFilter ref={ref} />
        </Box>
      )}
      {isMobile && (
        <Box>
          <EntityBox
            selectedId={selectedId}
            entities={selectedEntity}
            handleClick={toggleIsSelecting}
          />
          <Drawer
            anchor="bottom"
            open={isSelecting}
            transitionDuration={500}
            PaperProps={{
              className: drawerPaper
            }}>
            {farmTreeElement}
            <FarmTreeFilter ref={ref} />
          </Drawer>
        </Box>
      )}
    </>
  )
}

export default FarmTree

FarmTree.propTypes = {
  flattenedEntities: PropTypes.object,
  previousSelectedId: PropTypes.string,
  selectedId: PropTypes.string
}
