import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { COUNTRIES } from 'const'
import Snackbar from '@nutrien/uet-react/Snackbar'
import useTheme from '@nutrien/uet-react/styles/useTheme'
import _noop from 'lodash/noop'
import CluLayerContainer from 'components/GeoSpatial/components/CluLayer/CluLayerContainer'
import BasicMap from 'components/GeoSpatial/components/BasicMap'
import MapSearch from 'components/GeoSpatial/components/MapSearch'
import { getLayerByName } from 'components/GeoSpatial/utility/mapHelpers'
import useFeatureSelect from 'components/GeoSpatial/hooks/useFeatureSelect'
import Draw from 'components/GeoSpatial/components/Draw'
import useMapZoom from 'hooks/useMapZoom'
import FieldsLayerPolygon from 'components/FieldsLayerPolygon'
import { selectFieldsFor, selectOrgFor } from 'store/selectors/farmTree'
import useToggle from 'hooks/useToggle'
import CountryBoundary, {
  TOPO_COUNTRY_LAYER_NAME
} from 'components/CountryBoundary'

const infoPosition = { vertical: 'bottom', horizontal: 'left' }

const Wrapper = styled.div`
  position: relative;
  flex: 1 0 auto;

  .geolocation {
    width: 240px;
    position: absolute;
    left: 10px;
    top: 50px;
  }

  .drawButton {
    position: absolute;
    left: 240px;
    top: 50px;
    margin: 0;
    height: 40px;
  }

  .searchBarAgrible {
    position: absolute;
    top: 50px;
    left: 10px;
    width: 245px;
    font-family: 'Open Sans';
    z-index: 1;
  }

  .zoomControlsWrapper {
    z-index: 1;
  }

  .MuiSnackbar-root {
    left: 10px;
    bottom: 38px;
    max-width: 320px;

    /*
     * this is to target the snackbar inside of the Draw component.
     * package which is not being maintained anymore as of 01/13/2022.
     */
    &.MuiSnackbar-anchorOriginTopRight {
      top: 176px;
      left: 8px;
      right: auto;
      bottom: auto;

      @media (min-width: 600px) {
        top: 184px;
      }
    }
  }

  .ol-scale-line {
    position: absolute;
    top: 8px;
    left: 10px;
    background: #fff;
    border-radius: 4px;
    height: 26px;
    display: block;
    font-family: 'Open Sans';
  }
`

const selectableLayers = ['fieldDrawing']

const MapEntry = ({
  entity,
  onSelect,
  selectedEntity,
  onDrawStart,
  onDrawEnd
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [hasBoundary, setHasBoundary] = useState(true)
  const [mapRef, setMapRef] = useState(null)
  const [drawing, toggleDrawing] = useToggle(false)
  const [drawingPointsCount, setDrawingPointsCount] = useState(0)
  const zoom = useMapZoom(mapRef)
  const [infoMessage, setInfoMessage] = useState('add_field_message_1')
  const [infoVariant, setInfoVariant] = useState('info')
  const myOrg = useSelector(
    state => selectedEntity && selectOrgFor(state, selectedEntity.uuid)
  )
  const country = myOrg.country

  const worldExtent = [-180, -90, 180, 90]
  const basemap = useSelector(state => state.geospatial.basemap)
  const myFields =
    useSelector(state => myOrg && selectFieldsFor(state, myOrg.uuid)) || []

  // prevent unnecessary geospatial onSelect update
  const handleDrawSelect = useCallback(
    select => {
      onSelect(select.getFeatures().item(0))
    },
    [onSelect]
  )
  useFeatureSelect({
    map: mapRef,
    layers: selectableLayers,
    onSelect: handleDrawSelect
  })

  useEffect(() => {
    setInfoVariant('info')
    if (zoom < 14) setInfoMessage('add_field_message_1')
    else if (zoom >= 14) setInfoMessage('add_field_message_2')

    if (drawing) {
      switch (drawingPointsCount) {
        case 0:
          setInfoMessage('draw_message_0')
          break
        case 1:
        case 2:
          setInfoMessage('draw_message_1')
          break
        default:
          setInfoMessage('draw_message_2')
      }
    }
  }, [drawing, drawingPointsCount, zoom])

  useEffect(() => {
    if (!mapRef || entity) return

    const fieldDrawingLayer = getLayerByName(mapRef, 'fieldDrawing')
    const layerSource = fieldDrawingLayer.getSource()

    layerSource.getFeatures().forEach(f => layerSource.removeFeature(f))
  }, [entity, mapRef])

  const fieldsWithData = useMemo(
    () =>
      myFields.map(field => ({
        id: field.uuid,
        label: field.name,
        poly: field.poly,
        style: {
          fill: theme.palette.error.secondary
        }
      })),
    [myFields, theme.palette.error.secondary]
  )

  const handleToggleDrawing = useCallback(() => {
    if (!drawing) onDrawStart()
    else onDrawEnd()
    toggleDrawing()
  }, [drawing, onDrawEnd, onDrawStart, toggleDrawing])

  const handlePointCountChange = useCallback(
    (e, { drawLineString }) => {
      if (!drawLineString) return true
      const pointsCount = drawLineString.getGeometry().getCoordinates().length
      setDrawingPointsCount(pointsCount)
      return true
    },
    [setDrawingPointsCount]
  )
  const handleIsBoundaryLayer = event => {
    const pixel = event.map.getEventPixel(event.originalEvent)
    // limit to boundary layer if has boundary
    if (hasBoundary) {
      let clickInBoundary = false
      event.map.forEachFeatureAtPixel(pixel, (_, layer) => {
        if (layer && layer.get('name') === TOPO_COUNTRY_LAYER_NAME) {
          clickInBoundary = true
        }
      })
      return clickInBoundary
    } else {
      return true
    }
  }

  const handleOnAddingPoint = (event, args) => {
    if (!handleIsBoundaryLayer(event)) {
      setInfoMessage('draw_field_boundary_overflow')
      setInfoVariant('error')
      return false
    }
    return handlePointCountChange(event, args)
  }

  const mapFeatures = mapRef ? (
    <React.Fragment>
      <CountryBoundary
        map={mapRef}
        country={country}
        onAddingBoundary={setHasBoundary}
      />
      {country === COUNTRIES.US && (
        <CluLayerContainer
          map={mapRef}
          drawingOn={drawing}
          onClick={onSelect}
          clickEventType="click"
        />
      )}
      <div className="searchBarAgrible">
        <MapSearch map={mapRef} countryCode={country} />
      </div>
      <Draw
        map={mapRef}
        drawingOn={drawing}
        toggleDrawing={handleToggleDrawing}
        onAddingPoint={handleOnAddingPoint}
        onComplete={onSelect}
        onDeletingPoint={handlePointCountChange}
        maxAcres={1500}
        zoomRequirement={14}
      />

      {/*
        onSelect(null) below is to ignore args provided.
        This ensures that a click on owned field does not trigger the add new field dialog
        and effectively cancel out the action from CluLayer's onClick above
      */}
      <FieldsLayerPolygon
        map={mapRef}
        fields={fieldsWithData}
        onClick={() => onSelect(null)}
      />
      <Snackbar
        open
        anchorOrigin={infoPosition}
        onClose={_noop}
        message={t(infoMessage)}
        variant={infoVariant}
      />
    </React.Fragment>
  ) : null

  return (
    <Wrapper>
      <BasicMap createMapRef={setMapRef} basemap={basemap} extent={worldExtent}>
        {mapFeatures}
      </BasicMap>
    </Wrapper>
  )
}

MapEntry.propTypes = {
  entity: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  selectedEntity: PropTypes.object,
  onDrawStart: PropTypes.func.isRequired,
  onDrawEnd: PropTypes.func.isRequired
}

export default MapEntry
