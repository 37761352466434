import { combineReducers } from 'redux'
// import { WeatherStoryReducer as weatherStory } from '@nutrien/weather-story'
import { geospatialRootReducer as geospatial } from 'components/GeoSpatial/redux/reducers'

import farmTree from './farmTree'

const rootReducer = combineReducers({
  farmTree,
  geospatial
  // weatherStory
})

export default rootReducer
