export const CLEAR_POPUP_DATA = 'CLEAR_POPUP_DATA'
export const SAVE_FIELD_COORDINATES = 'SAVE_FIELD_COORDINATES'
export const SET_BASEMAP = 'SET_BASEMAP'
export const SET_MAP_ZOOM = 'SET_MAP_ZOOM'
export const SET_POPUP_DATA = 'SET_POPUP_DATA'
export const SET_RADAR_TIMEFRAME = 'SET_RADAR_TIMEFRAME'
export const SET_RADAR_VARIABLE = 'SET_RADAR_VARIABLE'
export const SET_RAINFALL_DIVISION = 'SET_RAINFALL_DIVISION'
export const SET_RAINFALL_TIMEFRAME = 'SET_RAINFALL_TIMEFRAME'
export const TOGGLE_RADAR_LAYER = 'TOGGLE_RADAR_LAYER'
export const TOGGLE_RAINFALL_LAYER = 'TOGGLE_RAINFALL_LAYER'
