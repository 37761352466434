// This has been copied and modified from https://github.com/nhagen/react-intercom/blob/master/src/index.js
// The original produced React warnings for componentWillReceiveProps
// The new 2.0 version is still in alpha and is buggy
import React from 'react'
import PropTypes from 'prop-types'
const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
)

export const IntercomAPI = (...args) => {
  if (canUseDOM && window.Intercom) {
    window.Intercom.apply(null, args)
  } else {
    // eslint-disable-next-line no-console
    console.warn('Intercom not initialized yet')
  }
}

export default class ReactIntercom extends React.Component {
  static propTypes = {
    appID: PropTypes.string.isRequired
  }

  static displayName = 'Intercom'

  constructor(props) {
    super(props)

    const { appID, ...otherProps } = props

    if (!appID || !canUseDOM) {
      return
    }

    if (!window.Intercom) {
      ;(function(w, d, id, s, x) {
        function i() {
          i.c(arguments)
        }
        i.q = []
        i.c = function(args) {
          i.q.push(args)
        }
        w.Intercom = i
        s = d.createElement('script')
        s.async = 1
        s.src = 'https://widget.intercom.io/widget/' + id
        d.head.appendChild(s)
      })(window, document, appID)
    }

    window.intercomSettings = { ...otherProps, app_id: appID }

    if (window.Intercom) {
      window.Intercom('boot', otherProps)
    }
  }

  componentDidUpdate(prevProps) {
    const { appID, ...otherProps } = this.props

    if (!canUseDOM) return

    window.intercomSettings = { ...otherProps, app_id: appID }

    if (window.Intercom) {
      if (this.loggedIn(prevProps) && !this.loggedIn(this.props)) {
        // Shutdown and boot each time the user logs out to clear conversations
        window.Intercom('shutdown')
        window.Intercom('boot', otherProps)
      } else {
        window.Intercom('update', otherProps)
      }
    }
  }

  componentWillUnmount() {
    if (!canUseDOM || !window.Intercom) return false

    window.Intercom('shutdown')

    delete window.Intercom
    delete window.intercomSettings
  }

  loggedIn(props) {
    return props.email || props.user_id
  }

  render() {
    return false
  }
}
