import Feature from 'ol/Feature'
import MultiPolygon from 'ol/geom/Polygon'
import OlSourceVector from 'ol/source/Vector'
import OlLayerVector from 'ol/layer/Vector'
import { easeOut } from 'ol/easing'
import { transform } from 'ol/proj'

export function createVectorLayerFromCoords(map, coords, name) {
  const vectorSource = new OlSourceVector({
    features: [
      new Feature({
        geometry: new MultiPolygon(coords)
      })
    ]
  })
  const vectorLayer = new OlLayerVector({
    name,
    map,
    source: vectorSource
  })
  return vectorLayer
}

export const getLayerByName = (map, name) => {
  const targetLayer = map
    .getLayers()
    .getArray()
    .find(layer => layer.get('name') === name)
  return targetLayer
}

export const formatExtent = extent => {
  const neCorner = transform([extent[2], extent[3]], 'EPSG:4326', 'EPSG:3857')
  const swCorner = transform([extent[0], extent[1]], 'EPSG:4326', 'EPSG:3857')
  return swCorner.concat(neCorner)
}

export function getLocation() {
  if (navigator.geolocation) {
    return new Promise((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(resolve, reject)
    )
  }
  return new Promise(resolve => resolve({}))
}

export function zoomToExtent(map, extent, padding = [8, 8, 8, 8]) {
  const view = map.getView()
  if (extent) {
    if (extent.length === 4) {
      const formattedExtent = formatExtent(extent)
      view.fit(formattedExtent, {
        duration: 800,
        minResolution: 4.7,
        padding,
        easing: easeOut
      })
    } else if (extent.length === 2) {
      const formattedCenter = transform(
        [extent[0], extent[1]],
        'EPSG:4326',
        'EPSG:3857'
      )
      view.animate({
        center: formattedCenter,
        duration: 800,
        resolution: 1.7
      })
    }
  }
}

export function deleteSelectedFeatures({ map, features, layer, onDelete }) {
  /*
    Removes features provided from the map

    map - ol Map class
    features - Array of features that need to be removed
    layer(optional) - ol layer to remove features from. If not provided,
      feature's layer name will need to be included in the feature properties
    onDelete(optional) - Callback function
  */
  if (onDelete) onDelete()
  if (!features) return
  features.forEach(feature => {
    const featureLayer =
      layer || getLayerByName(map, feature.getProperties().layer)
    if (featureLayer) featureLayer.getSource().removeFeature(feature)
  })
}
