import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import T from 'prop-types'
import Draw from 'ol/interaction/Draw'
import { Vector as VectorSource } from 'ol/source'
import { Vector as VectorLayer } from 'ol/layer'
import Feature from 'ol/Feature'
import MultiPolygon from 'ol/geom/MultiPolygon'
import { never } from 'ol/events/condition'
import Point from 'ol/geom/Point'
import kinks from '@turf/kinks'
import Snackbar from '@nutrien/uet-react/Snackbar'
import SnackbarContent from '@nutrien/uet-react/SnackbarContent'
import { lineString } from '@turf/helpers'
import { getArea } from 'ol/sphere'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'
import getNonCircleGeometry from '../../utility/getNonCircleGeometry'
import drawHoleInLayer from '../../utility/drawHoleInLayer'
import { newPolygonStyle } from '../../utility/featureStyles'

import {
  MAP_LAYER_FIELD_DRAWING,
  MAP_PROPTYPE,
  DRAW_END,
  METER_TO_ACRES_CONVERSION_FACTOR,
  MAP_LAYER_FIRST_POINT,
  MAP_LAYER_LAST_POINT
} from '../../constants/Geospatial'

import { GEOMETRY_POLYGON, GEOMETRY_LINESTRING } from '../../constants/geometry'

import {
  deletePointStyle,
  firstPointStyle,
  createDrawStyleFunction
} from './DrawStyle'
import './DrawInteraction.scss'

let drawInteraction
const deletePointSource = new VectorSource({})
const firstPointSource = new VectorSource({})
let deletePointLayer
let firstPointLayer
// default layer where drawn feature will be added
const fieldDrawing = new VectorLayer({
  source: new VectorSource({}),
  name: MAP_LAYER_FIELD_DRAWING,
  zIndex: 2,
  style: newPolygonStyle
})

const useStyles = makeStyles(theme => {
  return {
    snackRoot: {
      backgroundColor: theme.palette.warning.main
    }
  }
})

const DrawInteraction = ({
  drawingOn,
  layer,
  limitless,
  map,
  maxAcres,
  onAddingPoint,
  onComplete,
  onDeletingPoint,
  preventIntersect,
  toggleDrawing,
  type
}) => {
  const { t } = useTranslation('geospatial')
  const classes = useStyles()
  const [snackOpen, setSnackOpen] = useState(false)
  const [snackMessage, setSnackMessage] = useState(null)
  const drawLayer = layer || fieldDrawing
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return
    setSnackOpen(false)
  }

  const polygonSelfIntersects = coords => {
    if (coords.length < 2 || !preventIntersect) return false
    const linestringDrawing = lineString(coords)
    const selfIntersect = kinks(linestringDrawing)
    const intersectingPoints = selfIntersect.features
    if (intersectingPoints.length)
      setSnackMessage(t('Self-intersecting shapes are not allowed.'))
    return intersectingPoints.length
  }

  const polygonTooLarge = polygonGeometry => {
    if (!polygonGeometry.getCoordinates().length || !maxAcres || limitless)
      return false
    const sizeInAcres =
      getArea(polygonGeometry) * METER_TO_ACRES_CONVERSION_FACTOR
    if (sizeInAcres > maxAcres) setSnackMessage(t('Field drawing is too large'))
    return sizeInAcres > maxAcres
  }

  const createDeleteButton = point => {
    const lastPointFeature = new Feature({
      name: MAP_LAYER_LAST_POINT,
      geometry: new Point(point)
    })
    deletePointSource.clear()
    deletePointSource.addFeature(lastPointFeature)
    deletePointLayer = new VectorLayer({
      map,
      source: deletePointSource,
      style: deletePointStyle
    })
    deletePointLayer.setStyle(deletePointStyle)
  }

  // The DrawInteraction code expects the number of coordinates in lineString to match
  // the number of segments, yet OL doesn't do it like that when you delete
  // the last point, as it keeps it as part of the coordinates.
  // enforceLastDrawLinePointRemoval makes sure that the number of lineString coordinates
  // matches the number of points in it, except when there is only 1 point displayed,
  // as the lineString needs to have at least 2 points
  const enforceLastDrawLinePointRemoval = drawLineString => {
    const lineStringGeom = drawLineString && drawLineString.getGeometry()
    const lineStringCoords =
      (lineStringGeom && lineStringGeom.getCoordinates()) || []
    if (lineStringCoords.length > 1) {
      // drawLine needs 2 points at least
      lineStringGeom.setCoordinates(lineStringCoords.slice(0, -1))
    }
  }

  const removeLastPoint = (
    e,
    { drawFeatures, drawLineString, drawPolygon }
  ) => {
    drawInteraction.removeLastPoint(e, {
      drawFeatures,
      drawLineString,
      drawPolygon
    })
    enforceLastDrawLinePointRemoval(drawLineString)

    if (onDeletingPoint) {
      onDeletingPoint(e, {
        drawFeatures,
        drawLineString,
        drawPolygon
      })
    }
  }

  const addValidationButtonOnFirstPoint = fieldDrawingCoords => {
    firstPointLayer = new VectorLayer({
      map,
      source: firstPointSource,
      style: firstPointStyle,
      zIndex: 2
    })
    const firstPointFeature = new Feature({
      name: MAP_LAYER_FIRST_POINT,
      geometry: new Point(fieldDrawingCoords[0])
    })
    firstPointSource.clear()
    firstPointSource.addFeature(firstPointFeature)
  }

  const cleanupAndFinishDrawing = () => {
    deletePointSource.clear()
    map.removeLayer(deletePointLayer)

    firstPointSource.clear()
    map.removeLayer(firstPointLayer)
    drawInteraction.finishDrawing()
  }

  const removeValidationButtonOnFirstPoint = () => {
    firstPointSource.clear()
    map.removeLayer(firstPointLayer)
  }

  const createDeleteButtonIfNecessary = (currentPoint, fieldDrawingCoords) => {
    // needs enforceLastDrawLinePointRemoval to be reliable
    const newLastPointCoord = new Point(
      fieldDrawingCoords[fieldDrawingCoords.length - 3]
    )
    const newLastPoint = new Feature({
      name: MAP_LAYER_LAST_POINT,
      geometry: newLastPointCoord
    })

    if (
      currentPoint[0] !== fieldDrawingCoords[0][0] ||
      currentPoint[1] !== fieldDrawingCoords[0][1]
    ) {
      deletePointSource.addFeature(newLastPoint)
    }
  }

  const cleanupAndRemoveLastPointButton = (
    currentPoint,
    fieldDrawingCoords,
    e,
    { drawFeatures, drawLineString, drawPolygon }
  ) => {
    deletePointSource.clear()

    map.removeLayer(deletePointLayer)

    removeLastPoint(e, {
      drawFeatures,
      drawLineString,
      drawPolygon
    })

    if (fieldDrawingCoords.length > 2) {
      createDeleteButtonIfNecessary(currentPoint, fieldDrawingCoords)
    }
    if (fieldDrawingCoords.length <= 4) {
      removeValidationButtonOnFirstPoint()
    }
  }

  const mapClicked = e => {
    const drawFeatures = drawInteraction
      .getOverlay()
      .getSource()
      .getFeatures()

    const drawLineString = drawFeatures.find(
      feature => feature.getGeometry().getType() === GEOMETRY_LINESTRING
    )
    const drawPolygon = drawFeatures.find(
      feature => feature.getGeometry().getType() === GEOMETRY_POLYGON
    )

    if (onAddingPoint) {
      const shouldAddPoint = onAddingPoint(e, {
        drawFeatures,
        drawLineString,
        drawPolygon
      })

      if (!shouldAddPoint) {
        removeLastPoint(e, {
          drawFeatures,
          drawLineString,
          drawPolygon
        })
        return
      }
    }

    setSnackOpen(false)
    if (type !== GEOMETRY_POLYGON) return

    const fieldDrawingCoords = drawLineString.getGeometry().getCoordinates()

    const fieldDrawingPolygon = drawPolygon.getGeometry()

    if (
      !polygonSelfIntersects(fieldDrawingCoords) &&
      !polygonTooLarge(fieldDrawingPolygon)
    ) {
      createDeleteButton(e.coordinate)
    }
    const pixel = map.getEventPixel(e.originalEvent)
    const clickedFeature = map.forEachFeatureAtPixel(
      pixel,
      mapFeature => mapFeature
    )
    const clickedFeatureName =
      clickedFeature && clickedFeature.getProperties().name

    if (clickedFeatureName === MAP_LAYER_LAST_POINT) {
      const currentPoint = clickedFeature.getGeometry().getCoordinates()

      cleanupAndRemoveLastPointButton(currentPoint, fieldDrawingCoords, e, {
        drawFeatures,
        drawLineString,
        drawPolygon
      })
    } else if (clickedFeatureName === MAP_LAYER_FIRST_POINT) {
      // prevent completing drawing if geometry self-intersects
      if (polygonSelfIntersects(fieldDrawingCoords)) {
        setSnackOpen(true)
        return
      }
      cleanupAndFinishDrawing()
    } else if (fieldDrawingCoords.length === 3) {
      addValidationButtonOnFirstPoint(fieldDrawingCoords)
    }

    if (
      polygonTooLarge(fieldDrawingPolygon) ||
      polygonSelfIntersects(fieldDrawingCoords)
    ) {
      removeLastPoint(e, {
        drawFeatures,
        drawLineString,
        drawPolygon
      })
      setSnackOpen(true)
    }
  }

  const drawEndHandler = e => {
    const drawnFeature = new Feature({
      geometry: new MultiPolygon([
        getNonCircleGeometry(e.feature).getCoordinates()
      ])
    })
    const drawHole = drawHoleInLayer({
      layer: drawLayer,
      // drawnFeature is always MultiPolygon with one Polygon, so grab that for holeCandidate Polygon
      holeCandidate: drawnFeature.getGeometry().getPolygons()[0]
    })
    // add new feature if hole wasn't cut out of existing feature
    if (!drawHole) {
      const layerName = drawLayer
        ? drawLayer.getProperties().name
        : MAP_LAYER_FIELD_DRAWING
      // layer name is set in feature properties so that it can be used later to remove feature from layer if necessary
      drawnFeature.setProperties({ layer: layerName })
      drawLayer.getSource().addFeature(drawnFeature)
    }
    if (onComplete) onComplete(drawnFeature, drawLayer)
    toggleDrawing()
  }
  useEffect(() => {
    if (drawingOn) {
      drawInteraction = new Draw({
        source: new VectorSource(),
        type,
        minPoints: 1,
        condition: e => e.originalEvent.buttons === 1,
        finishCondition: type === GEOMETRY_POLYGON ? never : null,
        style: type === GEOMETRY_POLYGON ? createDrawStyleFunction : null
      })
      map.addInteraction(drawInteraction)
      drawInteraction.getOverlay().setZIndex(0)
      drawInteraction.on(DRAW_END, drawEndHandler)
      map.on('click', mapClicked)
    } else {
      map.removeInteraction(drawInteraction)
      deletePointSource.clear()
      firstPointSource.clear()
      map.removeLayer(deletePointLayer)
      map.removeLayer(firstPointLayer)
    }
    return () => {
      map.un('click', mapClicked)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawingOn])
  useEffect(() => {
    // if exisiting layer is not specified, add default fieldDrawing layer to map
    if (!layer) map.addLayer(fieldDrawing)
  }, [layer, map])
  return (
    <Snackbar open={snackOpen} autoHideDuration={2000} onClose={handleClose}>
      <SnackbarContent
        message={snackMessage}
        classes={{ root: classes.snackRoot }}
      />
    </Snackbar>
  )
}

DrawInteraction.propTypes = {
  map: T.shape(MAP_PROPTYPE).isRequired,
  drawingOn: T.bool.isRequired,
  limitless: T.bool,
  toggleDrawing: T.func.isRequired,
  onComplete: T.func,
  onAddingPoint: T.func,
  onDeletingPoint: T.func,
  type: T.string,
  preventIntersect: T.bool,
  maxAcres: T.number,
  layer: T.shape({
    getProperties: T.func
  })
}
DrawInteraction.defaultProps = {
  layer: null,
  limitless: false,
  maxAcres: null,
  onAddingPoint: null,
  onComplete: null,
  onDeletingPoint: null,
  preventIntersect: true,
  type: GEOMETRY_POLYGON
}

export default DrawInteraction
