const toggleArrayOfText = (value, arr) => {
  const itemAlreadyExists = arr.includes(value)
  const tempList = itemAlreadyExists
    ? arr.filter(item => item !== value)
    : [...arr, value]
  tempList.sort()

  return tempList
}

export default toggleArrayOfText
