import { createSelector } from 'reselect'
import get from 'lodash/get'
import getFlattenedEntities from './getFlattenedEntities'
import getActiveId from './getActiveId'

const getActiveEntity = createSelector(
  [getFlattenedEntities, getActiveId],
  (flattenedEntities, activeId) => {
    return get(flattenedEntities, activeId)
  }
)

export default getActiveEntity
