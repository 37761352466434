import React, { useCallback, useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'graphql-hooks'
import Button from '@nutrien/uet-react/Button'
import Dialog from '@nutrien/uet-react/Dialog'
import DialogActions from '@nutrien/uet-react/DialogActions'
import { ENTITY_TYPE_FIELDS, ENTITY_TYPES } from 'const'
import BonsaiDrawer from 'components/BonsaiDrawer'
import Typography from 'components/Typography'
import withRequest from 'HOCs/withRequest'
import { renameEntity, moveEntity, deleteEntity } from 'store/actions/FarmTree'
import { AuthContext } from 'components/Auth/authentication-context'
import useToggle from 'hooks/useToggle'
import EditEntity from 'components/EditEntity'

const EDIT_TREE_NODE = `
mutation NavigationTree($type: TreeType!, $uuid: String!, $newName: String!, $parentUuid: String, $profileUuid: String!) {
  editTreeNode(type: $type, uuid: $uuid, newName: $newName, parentUuid: $parentUuid, profileUuid: $profileUuid)
}
`

const DELETE_TREE_NODE = `
mutation NavigationTree($type: TreeType!, $uuid: String!, $profileUuid: String!) {
  deleteNode(type: $type, uuid: $uuid, profileUuid: $profileUuid)
}
`

const entityNameMap = {
  enterprise_groups: 'Enterprise Group',
  farms: 'farm',
  fields: 'field'
}

const EditEntityDialog = ({
  entity,
  isOpen,
  onClose,
  setRequestPending,
  setRequestError,
  flattenedEntities,
  navigateOnDelete,
  manuallySetSelectedField = false
}) => {
  const { user } = useContext(AuthContext)
  const profileUuid = user?.id
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [editTreeNode] = useMutation(EDIT_TREE_NODE)
  const [deleteTreeNode] = useMutation(DELETE_TREE_NODE)

  const [isDeleting, toggleIsDeleting] = useToggle(false)
  const [nameState, setName] = useState(entity.name)
  const [parentIdState, setParentId] = useState(entity.parentUUID)
  useEffect(() => {
    setName(entity.name)
    setParentId(entity.parentUUID)
  }, [entity.name, entity.parentUUID])

  const handleSubmit = useCallback(async () => {
    setRequestPending(true)
    const { error } = await editTreeNode({
      variables: {
        newName: nameState,
        parentUuid: parentIdState,
        type: entity.type,
        uuid: entity.uuid,
        profileUuid: profileUuid
      }
    })
    setRequestPending(false)
    if (error) {
      setRequestError(t('failed_to_save'))
    }
    onClose()
    if (entity.parentUUID !== parentIdState) {
      dispatch(moveEntity({ parentUUID: parentIdState, uuid: entity.uuid }))
    }
    if (entity.name !== nameState) {
      dispatch(renameEntity({ name: nameState, uuid: entity.uuid }))
    }
  }, [
    dispatch,
    nameState,
    parentIdState,
    setRequestPending,
    editTreeNode,
    entity,
    onClose,
    setRequestError,
    profileUuid,
    t
  ])

  const handleDeleteConfirm = useCallback(async () => {
    setRequestPending(true)
    toggleIsDeleting()
    !manuallySetSelectedField && onClose()
    if (!entity.canDelete) {
      for (const childUUID of entity.childrenUUIDs) {
        const child = flattenedEntities[childUUID]
        if (child.canDelete) {
          const { error } = await deleteTreeNode({
            variables: {
              type: ENTITY_TYPE_FIELDS,
              uuid: childUUID,
              profileUuid: profileUuid
            }
          })
          if (error) {
            setRequestError(t('failed_to_save'))
            return { type: 'NOOP' } // shared component makes this mandatory
          }
          dispatch(deleteEntity({ uuid: childUUID }))
        }
      }
      setRequestPending(false)
    } else {
      const { error } = await deleteTreeNode({
        variables: {
          type: entity.type,
          uuid: entity.uuid,
          profileUuid: profileUuid
        }
      })
      setRequestPending(false)
      if (error) {
        setRequestError(t('failed_to_save'))
        return { type: 'NOOP' } // shared component makes this mandatory
      }
      if (navigateOnDelete) {
        navigateOnDelete()
      }
      dispatch(deleteEntity({ manuallySetSelectedField, uuid: entity.uuid }))
      manuallySetSelectedField && onClose()
    }
  }, [
    setRequestPending,
    toggleIsDeleting,
    manuallySetSelectedField,
    onClose,
    entity.canDelete,
    entity.childrenUUIDs,
    entity.type,
    entity.uuid,
    flattenedEntities,
    deleteTreeNode,
    dispatch,
    setRequestError,
    profileUuid,
    t,
    navigateOnDelete
  ])

  if (!entity) {
    return null
  }

  return (
    <>
      <BonsaiDrawer
        buttonType="reset"
        footerButtonLabel={t('save')}
        isFooterButtonDisabled={
          entity.parentUUID === parentIdState && entity.name === nameState
        }
        onClose={onClose}
        onFooterButtonClick={handleSubmit}
        open={isOpen}
        subtitle={entity.name}
        title={t('edit_TYPE_details', {
          type: t(
            ENTITY_TYPES.ORGS === entity.type
              ? 'organization'
              : entityNameMap[entity.type]
          )
        })}>
        <EditEntity
          entity={entity}
          nameState={nameState}
          onClickDelete={toggleIsDeleting}
          parentIdState={parentIdState}
          setName={setName}
          setParentId={setParentId}
        />
      </BonsaiDrawer>
      <Dialog
        onClose={toggleIsDeleting}
        open={isDeleting}
        title={`${t('delete')} ${entity.name}`}>
        <Typography variant="body1">{t('delete_confirmation_text')}</Typography>
        <DialogActions>
          <Button color="primary" onClick={toggleIsDeleting} variant="outlined">
            {t('cancel')}
          </Button>
          <Button
            autoFocus
            color="primary"
            onClick={handleDeleteConfirm}
            variant="contained">
            {t('delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

EditEntityDialog.propTypes = {
  entity: PropTypes.object,
  flattenedEntities: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  navigateOnDelete: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  setRequestError: PropTypes.func.isRequired,
  setRequestPending: PropTypes.func.isRequired
}

export default withRequest(EditEntityDialog)
