import React from 'react'
import T from 'prop-types'
import * as olProj from 'ol/proj'

import zoomIn from '../../assets/ic_add.svg'
import zoomOut from '../../assets/ic_remove.svg'
import world from '../../assets/ic_world.svg'
import { MAP_PROPTYPE } from '../../constants/Geospatial'
import { setMapZoom } from '../../redux/actions/geospatialActions'

import StateMachineContext from '../../context/StateMachineContext'

import './ZoomControls.scss'

const ZoomControls = ({ map, worldZoom, center }) => {
  const { useDispatch } = React.useContext(StateMachineContext)
  const dispatch = useDispatch()

  const zoomInClick = targetMap => {
    if (targetMap.getView().getZoom() < 23) {
      targetMap.getView().animate({
        duration: 500,
        zoom: targetMap.getView().getZoom() + 1
      })
      dispatch(setMapZoom('Zoom In'))
    }
  }

  const zoomOutClick = targetMap => {
    if (targetMap.getView().getZoom() > 3) {
      targetMap.getView().animate({
        zoom: targetMap.getView().getZoom() - 1,
        duration: 500
      })
      dispatch(setMapZoom('Zoom Out'))
    }
  }

  const worldZoomClick = (targetMap, centerPoint) => {
    targetMap.getView().animate({
      zoom: 4,
      center: olProj.transform(centerPoint, 'EPSG:4326', 'EPSG:3857'),
      duration: 300
    })
    dispatch(setMapZoom('Center'))
  }

  return (
    <div className="zoomControlsWrapper">
      <button
        id="zoomIn"
        data-testid="zoomIn"
        className="zoomControlsButton"
        onClick={() => zoomInClick(map)}
        type="button">
        <img src={zoomIn} alt="zoom in" />
      </button>
      <button
        id="zoomOut"
        data-testid="zoomOut"
        className="zoomControlsButton"
        onClick={() => zoomOutClick(map)}
        type="button">
        <img src={zoomOut} alt="zoom out" />
      </button>
      {worldZoom && (
        <button
          id="worldZoom"
          data-testid="worldZoom"
          className="zoomControlsButton"
          onClick={() => worldZoomClick(map, center)}
          type="button">
          <img src={world} alt="world zoom" />
        </button>
      )}
    </div>
  )
}

ZoomControls.propTypes = {
  map: T.shape(MAP_PROPTYPE).isRequired,
  worldZoom: T.bool,
  center: T.arrayOf(T.number)
}

ZoomControls.defaultProps = {
  worldZoom: true,
  center: [-98, 40]
}

export default ZoomControls
