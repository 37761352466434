import React, { useCallback, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import ListItemIcon from '@nutrien/uet-react/ListItemIcon'
import Box from '@nutrien/uet-react/Box'
import { useTranslation } from 'react-i18next'
import ListItemText from '@nutrien/uet-react/ListItemText'
import ListItem from '@nutrien/uet-react/ListItem'
import SvgIcon from '@nutrien/uet-react/SvgIcon'
import bonsaiTheme from '@nutrien/bonsai-theme/build'
import { ENTITY_TYPE_FIELDS, ENTITY_TYPE_FARMS, ENTITY_TYPE_ORGS } from 'const'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'
import ListItemSecondaryAction from '@nutrien/uet-react/ListItemSecondaryAction'
import Popper from '@nutrien/uet-react/Popper'
import FieldThumbnail from 'components/GeoSpatial/components/FieldThumbnail'
import useToggle from 'hooks/useToggle'
import { FarmIcon } from 'components/Icons'
import Caret from 'components/Caret'
import useAcreageConverter from 'hooks/useAcreageConverter/useAcreageConverter'

const useStyles = makeStyles(theme => ({
  caretBorder: {
    borderRadius: '4px',
    border: `1px solid ${theme.palette.gray[200]}`,
    marginTop: '6px'
  },
  ineligible: {
    color: `${theme.palette.gray[200]}`
  },
  cursorPointer: { cursor: 'pointer' },
  lockedChip: { fontWeight: '600' },
  lockedChipTooltip: {
    maxWidth: 'none'
  },
  missingData: {
    color: theme.palette.warning.light
  },
  popperStyles: {
    width: '300px'
  },
  selected: {
    backgroundColor: '#EDF3F7 !important'
  }
}))

const SelectedItemIndicator = ({ selected }) =>
  selected && (
    <Box
      data-testid={'selected-item-indicator'}
      borderLeft={`6px solid ${bonsaiTheme.colors.brand.twilight}`}
      position="absolute"
      top={0}
      bottom={0}
      left={0}
    />
  )

const FarmTreeListItem = ({
  primaryText,
  caret,
  entity,
  type,
  fieldProps: { poly, extent } = {},
  selected,
  onClick,
  onCaretClick,
  acreage
}) => {
  const { t } = useTranslation()
  const {
    caretBorder,
    ineligible,
    cursorPointer,
    lockedChip,
    lockedChipTooltip,
    missingData,
    popperStyles,
    ...classes
  } = useStyles()
  const listEl = useRef(null)
  const [showPopper, toggleShowPopper] = useToggle(false)
  const shouldShowIcon = useMemo(
    () => [ENTITY_TYPE_FIELDS, ENTITY_TYPE_FARMS].includes(type),
    [type]
  )

  const isOrg = useMemo(() => type === ENTITY_TYPE_ORGS, [type])
  const isField = poly && extent

  const closePopper = useCallback(
    e => {
      toggleShowPopper()
      e.stopPropagation()
    },
    [toggleShowPopper]
  )

  const converter = useAcreageConverter(true)

  const getSecondaryText = useCallback(() => {
    if (acreage) {
      const { areaValue, areaUnit } = converter(acreage)
      return `${areaValue} ${t(areaUnit)}`
    }
    if (isOrg) {
      const { isOwner, sharedBy } = entity
      return isOwner ? t('my_org') : `${t('shared_by')} ${sharedBy?.name}`
    }
  }, [acreage, isOrg, t, entity, converter])

  return (
    <ListItem
      button
      selected={selected}
      classes={classes}
      onClick={onClick}
      ref={listEl}>
      <Popper
        className={popperStyles}
        open={showPopper}
        onClose={closePopper}
        anchorEl={listEl.current}
        message={t('Ineligible Reason')}
        placement="bottom"
      />
      <SelectedItemIndicator
        selected={selected}
        data-testid={'selected-item-indicator'}
      />
      {shouldShowIcon && (
        <ListItemIcon>
          <Box center="y" width={40} height={40}>
            {isField ? (
              <Box bgcolor="grey.A15">
                <FieldThumbnail
                  width={32}
                  height={32}
                  fieldGeometry={poly}
                  extent={extent}
                />
              </Box>
            ) : (
              <div>
                <SvgIcon>
                  <FarmIcon />
                </SvgIcon>
              </div>
            )}
          </Box>
        </ListItemIcon>
      )}
      <ListItemText
        primaryTypographyProps={{
          variant: isField ? 'body1' : 'h5',
          noWrap: true
        }}
        primary={primaryText}
        secondaryTypographyProps={{ variant: 'caption', noWrap: true }}
        secondary={getSecondaryText()}
      />
      <ListItemSecondaryAction onClick={onCaretClick} className={cursorPointer}>
        <Caret direction={caret} className={isOrg ? caretBorder : null} />
      </ListItemSecondaryAction>
    </ListItem>
  )
}

SelectedItemIndicator.propTypes = {
  selected: PropTypes.bool
}

FarmTreeListItem.propTypes = {
  caret: PropTypes.oneOf(['up', 'down']),
  onCaretClick: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  fieldProps: PropTypes.shape({ poly: PropTypes.any, extent: PropTypes.any }),
  primaryText: PropTypes.node.isRequired,
  type: PropTypes.string,
  selected: PropTypes.bool.isRequired,
  acreage: PropTypes.number,
  entity: PropTypes.object,
  isFilteredByEligibility: PropTypes.bool
}

export default FarmTreeListItem
