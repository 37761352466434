import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'graphql-hooks'
import Dialog from '@nutrien/uet-react/Dialog'
import Button from '@nutrien/uet-react/Button'
import * as Yup from 'yup'
import Typography from '@nutrien/uet-react/Typography'
import TextField from '@nutrien/uet-react/TextField'
import MenuItem from '@nutrien/uet-react/MenuItem'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'

import { countriesList } from 'const'
import useInputs from 'hooks/useInputs'
import { USER_OBJECT } from 'components/auth-providers/AgribleBackend/AgribleBackend'

const useStyles = makeStyles(theme => ({
  dialogContent: {
    borderTop: `1px solid ${theme.palette.divider}`,
    maxHeight: 300,
    boxSizing: 'border-box',
    padding: theme.spacing(1, 0, 2, 0)
  },
  textField: {
    marginBottom: theme.spacing(2)
  },
  restrictionMessage: {
    width: 368,
    fontSize: 12
  },
  customerService: {
    fontSize: 12,
    color: theme.palette.brand.green,
    cursor: 'pointer'
  }
}))

const USER_INFO_MUTATION = `
mutation EditUser(
  $firstName: String!
  $lastName: String!
  $addressName: String!
  $addressLine1: String!
  $addressLine2: String!
  $city: String!
  $zipCode: String!
  $state: String!
  $country: String!
  $phoneNumber: String!
) {
  editUser(
    firstName: $firstName
    lastName: $lastName
    profile: {
      address: {
        name: $addressName
        addressLine1: $addressLine1
        addressLine2: $addressLine2
        city: $city
        zipCode: $zipCode
        state: $state
        country: $country
        phoneNumber: $phoneNumber
      }
    }
  ) ${USER_OBJECT}
}
`

const ChangeInfoDialog = ({
  userInfo,
  onClose,
  onSuccess,
  setRequestPending,
  setRequestError
}) => {
  const { t } = useTranslation()
  const styles = useStyles()
  const [updateUserInfo] = useMutation(USER_INFO_MUTATION)
  const countryList = countriesList(t)
  const countries = countryList.map(country => country.value)

  const schema = useMemo(
    () => ({
      firstName: Yup.string().required(t('first_name_error')),
      lastName: Yup.string().required(t('last_name_error')),
      addressName: Yup.string().nullable(),
      addressLine1: Yup.string().nullable(),
      addressLine2: Yup.string().nullable(),
      city: Yup.string().nullable(),
      zipCode: Yup.string().nullable(),
      state: Yup.string().nullable(),
      country: Yup.string().required(t('country_error')),
      phoneNumber: Yup.string().nullable()
    }),
    [t]
  )

  const { inputs, getValues, isValid } = useInputs(userInfo, schema)
  const {
    firstName,
    lastName,
    addressName,
    addressLine1,
    addressLine2,
    city,
    zipCode,
    state,
    country,
    phoneNumber
  } = inputs

  const handleSubmit = useCallback(async () => {
    setRequestPending(true)
    const { data, error } = await updateUserInfo({ variables: getValues() })
    setRequestPending(false)

    if (error) return setRequestError(t('api_error_title'))

    if (data && data.editUser) onSuccess(data.editUser)
  }, [
    getValues,
    onSuccess,
    setRequestError,
    setRequestPending,
    t,
    updateUserInfo
  ])

  return (
    <Dialog
      title={t('change_info')}
      open
      onClose={onClose}
      hasCloseIcon
      maxWidth="xs"
      closeIconSize="large"
      actions={
        <>
          <Button variant="outlined" onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={!isValid()}
            data-testid="submitBtn">
            {t('save')}
          </Button>
        </>
      }>
      <div className={styles.dialogContent}>
        <Typography variant="body1">{t('edit_info_message')}</Typography>
        <br />
        <TextField
          className={styles.textField}
          inputProps={{ 'data-testid': 'firstName' }}
          label={t('first_name')}
          {...firstName.bind}
          autoFocus
        />
        <TextField
          className={styles.textField}
          inputProps={{ 'data-testid': 'lastName' }}
          label={t('last_name')}
          {...lastName.bind}
        />
        <TextField
          className={styles.textField}
          inputProps={{ 'data-testid': 'addressName' }}
          label={t('address_name')}
          {...addressName.bind}
        />
        <TextField
          className={styles.textField}
          inputProps={{ 'data-testid': 'addressLine1' }}
          label={t('address_line_1')}
          {...addressLine1.bind}
        />
        <TextField
          className={styles.textField}
          inputProps={{ 'data-testid': 'addressLine2' }}
          label={t('address_line_2')}
          {...addressLine2.bind}
        />
        <TextField
          className={styles.textField}
          inputProps={{ 'data-testid': 'city' }}
          label={t('city')}
          {...city.bind}
        />
        <TextField
          className={styles.textField}
          inputProps={{ 'data-testid': 'zipCode' }}
          label={t('zip_code')}
          {...zipCode.bind}
        />
        <TextField
          className={styles.textField}
          inputProps={{ 'data-testid': 'state' }}
          label={t('state')}
          {...state.bind}
        />
        <TextField
          className={styles.textField}
          inputProps={{ 'data-testid': 'country' }}
          SelectProps={{
            SelectDisplayProps: { 'data-testid': 'country-select' }
          }}
          label={t('country')}
          select
          {...country.bind}>
          {countries.map(code => (
            <MenuItem key={code} value={code}>
              {t(code.toLowerCase())}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          className={styles.textField}
          inputProps={{ 'data-testid': 'phoneNumber' }}
          label={t('phone_number')}
          {...phoneNumber.bind}
        />
      </div>
    </Dialog>
  )
}

ChangeInfoDialog.propTypes = {
  userInfo: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  setRequestPending: PropTypes.func.isRequired,
  setRequestError: PropTypes.func.isRequired
}

export default ChangeInfoDialog
