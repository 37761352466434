import React from 'react'
import T from 'prop-types'
import get from 'lodash/get'
import geojson2svg from 'geojson2svg'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  fieldThumbnail: {
    fill: theme.palette.grey.A200
  }
}))

const FieldThumbnail = ({ fieldGeometry, extent, width }) => {
  const classes = useStyles()
  let svg = null
  const fieldPolys = get(fieldGeometry, 'coordinates', null)
  if (!fieldPolys) {
    return null
  }
  const [xmin, ymin, xmax, ymax] = extent
  let fieldWidth = 0
  let fieldHeight = 0
  const polyWidth = xmax - xmin
  const polyHeight = ymax - ymin
  const polyRatio = polyWidth / polyHeight
  if (polyWidth > polyHeight) {
    fieldWidth = width
    fieldHeight = width / polyRatio
  } else {
    fieldWidth = width * polyRatio
    fieldHeight = width
  }
  const converter = geojson2svg({
    viewportSize: { width: fieldWidth, height: fieldHeight },
    mapExtent: { left: xmin, bottom: ymin, right: xmax, top: ymax },
    output: 'path'
  })
  const svgPath = converter.convert(fieldGeometry)
  svg = (
    <svg
      className={classes.fieldThumbnail}
      height={fieldHeight}
      width={fieldWidth}
      data-testid="field-thumbnail">
      <path d={svgPath} />
    </svg>
  )
  return svg
}

FieldThumbnail.propTypes = {
  fieldGeometry: T.shape({
    coordinates: T.arrayOf(T.array)
  }),
  extent: T.arrayOf(T.number),
  width: T.number
}

FieldThumbnail.defaultProps = {
  fieldGeometry: null,
  extent: null,
  width: 100
}

export default FieldThumbnail
