import StyleStyle from 'ol/style/Style'
import StyleStroke from 'ol/style/Stroke'
import StyleCircle from 'ol/style/Circle'
import StyleFill from 'ol/style/Fill'
import StyleText from 'ol/style/Text'

export function rainFallFeatureStyle(rainfallValue) {
  let featureColor = 'rgba(102,102,102,0.5)'
  if (rainfallValue === 'light') {
    featureColor = 'rgba(51,145,188,0.5)'
  }
  if (rainfallValue === 'medium') {
    featureColor = 'rgba(42,101,172,0.5)'
  }
  if (rainfallValue === 'heavy') {
    featureColor = 'rgba(37,51,148,0.5)'
  }
  if (rainfallValue === 'critical') {
    featureColor = 'rgba(10,21,101,0.5)'
  }
  return [
    new StyleStyle({
      stroke: new StyleStroke({
        color: 'rgba(255, 255, 255,1)',
        width: 0.5
      }),
      fill: new StyleFill({
        color: featureColor
      })
    })
  ]
}

export const availableFieldsStyle = new StyleStyle({
  stroke: new StyleStroke({
    color: 'rgba(255,255,255, 0.4)',
    width: 2,
    lineDash: [8, 8]
  }),
  fill: new StyleFill({
    color: 'rgba(255,255,255,0.05)'
  })
})

export function availableFieldsStyleHighlight() {
  return () => {
    const style = new StyleStyle({
      stroke: new StyleStroke({
        color: 'rgba(255,255,255, 0.8)',
        width: 2,
        lineDash: [8, 8]
      }),
      fill: new StyleFill({
        color: 'rgba(255,255,255,0.2)'
      }),
      text: new StyleText({
        font: '12px Calibri,sans-serif',
        fill: new StyleFill({
          color: '#000'
        }),
        stroke: new StyleStroke({
          color: '#00f',
          width: 3
        })
      })
    })
    return [style]
  }
}

export const newPolygonStyle = new StyleStyle({
  stroke: new StyleStroke({
    color: 'rgba(255,255,255, 0.9)',
    width: 4
  }),
  fill: new StyleFill({
    color: 'rgba(255,255,255,0.4)'
  })
})

export function countryBoundaryStyle() {
  return () => {
    const style = new StyleStyle({
      stroke: new StyleStroke({
        color: '#CCCCCC',
        width: 1
      }),
      fill: new StyleFill({
        color: 'rgba(255,255,255,0.0)'
      })
    })
    return [style]
  }
}

export function fieldPointStyle() {
  const style = new StyleStyle({
    image: new StyleCircle({
      radius: 6,
      stroke: new StyleStroke({
        color: 'white',
        width: 2
      }),
      fill: new StyleFill({
        color: '#52a112'
      })
    })
  })
  return [style]
}

export function selectedFieldPointStyle(zIndex) {
  const style = new StyleStyle({
    image: new StyleCircle({
      radius: 6,
      stroke: new StyleStroke({
        color: 'white',
        width: 2
      }),
      fill: new StyleFill({
        color: 'rgba(0, 153, 255, 1)'
      })
    }),
    zIndex
  })
  return [style]
}

export function fieldPolygonStyle(feature) {
  const style = new StyleStyle({
    fill: new StyleFill({
      color: 'rgba(204,204,204,0.4)'
    }),
    stroke: new StyleStroke({
      color: 'rgba(204,204,204,1.0)',
      width: 2
    }),
    text: new StyleText({
      text: feature.getProperties().field_name,
      font: '15px Gotham SSm A, Gotham SSm B',
      fill: new StyleFill({ color: '#FFFFFF' }),
      stroke: new StyleStroke({ color: [0, 0, 0, 0.6], width: 6 })
    })
  })
  return [style]
}

export function pointStyle() {
  const style = new StyleStyle({
    image: new StyleCircle({
      radius: 6,
      fill: new StyleFill({
        color: '#000000'
      })
    })
  })
  return [style]
}

export function selectedFieldStyle(feature) {
  const outerLine = new StyleStyle({
    stroke: new StyleStroke({
      color: 'rgba(255, 255, 255, 1)',
      width: 6
    })
  })
  const innerLineAndFill = new StyleStyle({
    fill: new StyleFill({
      color: 'rgba(204,204,204,0.4)',
      width: 2
    }),
    stroke: new StyleStroke({
      color: 'rgba(0, 153, 255, 1)',
      width: 3
    }),
    text: new StyleText({
      text: feature.getProperties().field_name,
      font: '15px Gotham SSm A, Gotham SSm B',
      fill: new StyleFill({ color: '#FFFFFF' }),
      stroke: new StyleStroke({ color: [0, 0, 0, 0.6], width: 6 })
    })
  })
  return [outerLine, innerLineAndFill]
}
