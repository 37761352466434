import { createSelector } from 'reselect'
import getFlattenedEntities from './getFlattenedEntities'
import getOpenedIds from './getOpenedIds'
import getPrimaryOrgId from './getPrimaryOrgId'
import getSearchString from './getSearchString'

const childrenToShow = ({
  entitiesToRecurse,
  flattenedEntities,
  openedIds
}) => {
  const treeItems = []
  entitiesToRecurse.forEach(entityUUID => {
    treeItems.push(entityUUID)
    const entity = flattenedEntities[entityUUID]
    if (entity) {
      const { childrenUUIDs, type } = entity
      if (type !== 'fields' && entityUUID.indexOf('no_children') === -1) {
        if (Array.isArray(openedIds) && openedIds.includes(entityUUID)) {
          let childrenOfOpenEntity
          if (childrenUUIDs) {
            childrenOfOpenEntity = childrenUUIDs
          } else {
            childrenOfOpenEntity = [`no_children_for_${type}`]
          }
          treeItems.push(
            ...childrenToShow({
              entitiesToRecurse: childrenOfOpenEntity,
              flattenedEntities,
              openedIds
            })
          )
        }
      }
    }
  })
  return [...treeItems]
}

const getShowableIds = createSelector(
  [getFlattenedEntities, getOpenedIds, getPrimaryOrgId, getSearchString],
  (flattenedEntities, openedIds, primaryOrgId, searchString) => {
    const initialEntitiesUUIDs = [primaryOrgId] // only targeting primary org for now
    let showableUUIDs
    const usableIds =
      searchString && searchString !== ''
        ? Object.keys(flattenedEntities)
        : openedIds
    if (flattenedEntities) {
      showableUUIDs = childrenToShow({
        entitiesToRecurse: initialEntitiesUUIDs,
        flattenedEntities,
        openedIds: usableIds
      })
    }
    return showableUUIDs
  }
)

export default getShowableIds
