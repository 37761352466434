import React from 'react'
import T from 'prop-types'

import CollapseIcon from '@nutrien/uet-react/icons/Collapse'
import ExpandIcon from '@nutrien/uet-react/icons/Expand'
import theme from '@nutrien/uet-theme'
import StateMachineContext from '../../context/StateMachineContext'

import { setMapZoom } from '../../redux/actions/geospatialActions'

const FullScreenIcon = ({ expanded, expandClick }) => {
  const [screenSize, setScreenSize] = React.useState('Full Screen')
  const { useDispatch } = React.useContext(StateMachineContext)
  const dispatch = useDispatch()

  const onClick = () => {
    expandClick()
    dispatch(setMapZoom(screenSize))
    setScreenSize(
      screenSize === 'Small Screen' ? 'Full Screen' : 'Small Screen'
    )
  }

  return expanded ? (
    <CollapseIcon
      data-testid="expandIcon"
      onClick={onClick}
      className="mapExpand"
      style={{ color: theme.palette.common.white }}
    />
  ) : (
    <ExpandIcon
      data-testid="expandIcon"
      onClick={onClick}
      className="mapExpand"
      style={{ color: theme.palette.common.white }}
    />
  )
}

FullScreenIcon.propTypes = {
  expanded: T.bool.isRequired,
  expandClick: T.func.isRequired
}

export default FullScreenIcon
