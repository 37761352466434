export default currentEntityObj => {
  const entities =
    currentEntityObj.account ||
    currentEntityObj.orgs ||
    currentEntityObj.org_group ||
    currentEntityObj.enterprise_groups ||
    currentEntityObj.farms ||
    currentEntityObj.fields ||
    []
  return entities
}
