import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import OlLayerVectorTile from 'ol/layer/VectorTile'
import OlSourceVectorTile from 'ol/source/VectorTile'
import OlFormatTopoJSON from 'ol/format/TopoJSON'
import OlLayerVector from 'ol/layer/Vector'
import OlSourceVector from 'ol/source/Vector'
import OlCollection from 'ol/Collection'
import * as OlTileGrid from 'ol/tilegrid'
import {
  availableFieldsStyle,
  availableFieldsStyleHighlight
} from '../../utility/featureStyles'
import { MAP_LAYER_CLU, MAP_PROPTYPE } from '../../constants/Geospatial'

let cluOverlay = new OlLayerVector({})
const boundaries = new OlLayerVectorTile({
  name: MAP_LAYER_CLU,
  source: new OlSourceVectorTile({
    format: new OlFormatTopoJSON(),
    projection: 'EPSG:900913',
    tileGrid: OlTileGrid.createXYZ({
      minZoom: 13,
      maxZoom: 13
    }),
    url: 'https://clucdn.nutrienagsolutions.com/tiles/{z}/{x}/{y}.topojson'
  }),
  style: availableFieldsStyle,
  maxResolution: 10
})

class CluLayer extends PureComponent {
  componentDidMount() {
    const { map, drawingOn, clickEventType } = this.props
    map.on(clickEventType, this.cluClick)
    map.removeLayer(boundaries)
    if (!drawingOn) {
      map.addLayer(boundaries)
    }
    cluOverlay = new OlLayerVector({
      map,
      source: new OlSourceVector({
        features: new OlCollection(),
        useSpatialIndex: false
      }),
      style: availableFieldsStyleHighlight(),
      zIndex: 1
    })
    map.on('pointermove', this.highlightCluFeature)
  }

  componentDidUpdate(prevProps) {
    const { drawingOn, map } = this.props
    if (prevProps.drawingOn !== drawingOn) {
      if (drawingOn) {
        map.removeLayer(boundaries)
      } else {
        map.addLayer(boundaries)
      }
    }
  }

  componentWillUnmount() {
    const { map, clickEventType } = this.props
    map.un('pointermove', this.highlightCluFeature)
    map.un(clickEventType, this.cluClick)
    map.removeLayer(boundaries)
    cluOverlay.getSource().clear()
    map.removeLayer(cluOverlay)
  }

  highlightCluFeature = evt => {
    const { map } = this.props
    const pixel = map.getEventPixel(evt.originalEvent)
    this.highlightFeature(pixel)
  }

  highlightFeature = pixel => {
    const { map } = this.props
    let hoverFeature = null
    map.forEachFeatureAtPixel(pixel, (mapFeature, mapLayer) => {
      if (mapLayer && mapLayer.get('name') === MAP_LAYER_CLU) {
        hoverFeature = mapFeature
      }
    })
    if (hoverFeature !== this.highlightedFeature) {
      if (this.highlightedFeature) {
        cluOverlay.getSource().removeFeature(this.highlightedFeature)
      }
      if (hoverFeature) {
        cluOverlay.getSource().addFeature(hoverFeature)
      }
      this.highlightedFeature = hoverFeature
    }
  }

  cluClick = e => {
    const { map, onClick, saveFieldCoordinates } = this.props
    const pixel = map.getEventPixel(e.originalEvent)
    const clickedFeature = map.forEachFeatureAtPixel(
      pixel,
      mapFeature => mapFeature
    )
    if (clickedFeature && clickedFeature.getProperties().gid) {
      saveFieldCoordinates(clickedFeature.getGeometry().getCoordinates())
      onClick(clickedFeature)
    }
  }

  render() {
    return null
  }
}

CluLayer.propTypes = {
  map: PropTypes.shape(MAP_PROPTYPE).isRequired,
  onClick: PropTypes.func.isRequired,
  drawingOn: PropTypes.bool.isRequired,
  saveFieldCoordinates: PropTypes.func.isRequired,
  clickEventType: PropTypes.oneOf(['singleclick', 'click', 'dblclick'])
}

CluLayer.defaultProps = {
  clickEventType: 'singleclick'
}

export default CluLayer
