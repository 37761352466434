import { createSelector } from 'reselect'
import get from 'lodash/get'
import getFlattenedEntities from './getFlattenedEntities'

const getPrimaryOrgId = createSelector(
  getFlattenedEntities,
  flattenedEntities =>
    get(flattenedEntities, ['owned-organizations', 'childrenUUIDs', 0])
)

export default getPrimaryOrgId
