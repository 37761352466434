import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(timezone)

const nowIsAfterDeadlineInPacificTime = deadline => {
  if (!deadline) return false
  const pacificDeadline = dayjs.tz(deadline, 'America/Los_Angeles').endOf('day')
  return dayjs().isAfter(pacificDeadline)
}

export const isPastEnrollmentDeadline = campaignDetails => {
  return nowIsAfterDeadlineInPacificTime(campaignDetails.enrollmentEndDate)
}

export const isPastSubmissionDeadline = campaignDetails => {
  return nowIsAfterDeadlineInPacificTime(campaignDetails.planDeadline)
}

export const isPastCampaignEndDate = campaignDetails => {
  return nowIsAfterDeadlineInPacificTime(campaignDetails.campaignEndDate)
}

export const isBeforeEnrollAndCmpgnEndDates = campaignDetails => {
  return !(
    isPastEnrollmentDeadline(campaignDetails) ||
    isPastCampaignEndDate(campaignDetails)
  )
}
