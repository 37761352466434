import React, { Component } from 'react'
import T from 'prop-types'
import { GEOMETRY_POLYGON } from '../../constants/geometry'
import { MAP_PROPTYPE } from '../../constants/Geospatial'
import DrawButton from '../DrawButton'
import DrawInteraction from '../DrawInteraction'

class Draw extends Component {
  constructor(props) {
    super(props)
    this.state = {
      drawButtonDisabled: !props.limitless
    }
  }

  componentDidMount() {
    const { map } = this.props
    map.getView().on('change:resolution', () => {
      this.disableDrawCheck(map.getView().getResolution())
    })
  }

  disableDrawCheck = mapZoom => {
    const { drawingOn, limitless, setCanDraw, zoomRequirement } = this.props
    const { drawButtonDisabled } = this.state
    if (!limitless && mapZoom < zoomRequirement && drawButtonDisabled) {
      this.setState({ drawButtonDisabled: false })
      setCanDraw(true)
    } else if (
      !limitless &&
      !drawButtonDisabled &&
      mapZoom > zoomRequirement &&
      !drawingOn
    ) {
      this.setState({ drawButtonDisabled: true })
      setCanDraw(false)
    } else if (limitless) {
      this.setState({ drawButtonDisabled: false })
      setCanDraw(true)
    }
  }

  render() {
    const {
      drawButton,
      drawingOn,
      layer, // Optional layer for drawn features to be added to.  Otherwise will be added to layer created in DrawInteraction
      limitless,
      map,
      maxAcres,
      onComplete,
      onAddingPoint,
      onDeletingPoint,
      setCanDraw,
      toggleDrawing,
      type
    } = this.props
    const { drawButtonDisabled } = this.state
    const content = (
      <>
        <DrawInteraction
          drawingOn={drawingOn}
          layer={layer}
          limitless={limitless}
          map={map}
          maxAcres={maxAcres}
          onAddingPoint={onAddingPoint}
          onComplete={onComplete}
          onDeletingPoint={onDeletingPoint}
          preventIntersect
          setCanDraw={setCanDraw}
          toggleDrawing={toggleDrawing}
          type={type}
        />
        {drawButton && (
          <DrawButton
            disabled={drawButtonDisabled}
            drawButton={drawButton}
            drawingOn={drawingOn}
            map={map}
            onClick={toggleDrawing}
          />
        )}
      </>
    )
    return content
  }
}
Draw.propTypes = {
  drawButton: T.bool,
  drawingOn: T.bool.isRequired,
  layer: T.shape({}),
  limitless: T.bool,
  map: T.shape(MAP_PROPTYPE).isRequired,
  maxAcres: T.number,
  onAddingPoint: T.func,
  onComplete: T.func,
  onDeletingPoint: T.func,
  setCanDraw: T.func,
  toggleDrawing: T.func.isRequired,
  type: T.string,
  zoomRequirement: T.number
}
Draw.defaultProps = {
  drawButton: true,
  layer: null,
  limitless: false,
  maxAcres: 1000,
  onAddingPoint: null,
  onComplete: null,
  onDeletingPoint: null,
  setCanDraw: () => {},
  type: GEOMETRY_POLYGON,
  zoomRequirement: 10
}

export default Draw
