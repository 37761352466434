import { PropTypes } from 'prop-types'

import completeDrawing from '../assets/complete_drawing.png'

export const MAP_PROPTYPE = {
  addLayer: PropTypes.func,
  removeLayer: PropTypes.func,
  getView: PropTypes.func,
  addInteraction: PropTypes.func,
  removeInteraction: PropTypes.func,
  getInteractions: PropTypes.func,
  getEventPixel: PropTypes.func,
  forEachFeatureAtPixel: PropTypes.func
}

// spatial references system (srs) codes
export const SRS_CODES = Object.freeze({
  EPSG_3857: 'EPSG:3857',
  EPSG_4326: 'EPSG:4326'
})

// basemaps
export const BASEMAP_SATELLITE = 'satellite'
export const BASEMAP_STREETS = 'streets'
export const BASEMAP_TERRAIN = 'terrain'

// radar options
export const RECENT_RAIN = 'doppler_radar'
export const FUTURE_RAIN = 'future_rain'
export const RECENT_TEMP = 'recent_temp'
export const FUTURE_TEMP = 'future_temp'
export const RECENT_WIND = 'recent_wind'
export const FUTURE_WIND = 'future_wind'

// filters
export const FILTER_RAIN = 'rain'
export const FILTER_TEMP = 'temp'
export const FILTER_RH = 'rh'
export const FILTER_GDD = 'gdd'
export const FILTER_STATE = 'state'
export const FILTER_ASD = 'asd'
export const FILTER_COUNTY = 'county'
export const FILTER_WIND = 'wind'

// layers
export const MAP_LAYER_CLU = 'cluLayer'
export const MAP_LAYER_CLU_SELECTIONS = 'cluSelectionsLayer'
export const MAP_LAYER_US_BOUNDARY = 'unitedStatesBoundary'
export const MAP_LAYER_FIELD_DRAWING = 'fieldDrawing'
export const MAP_LAYER_FIRST_POINT = 'firstPoint'
export const MAP_LAYER_LAST_POINT = 'lastPoint'
export const MAP_LAYER_FIELD_POINTS = 'fieldPoints'
export const MAP_LAYER_FIELD_POLYGONS = 'fieldPolygons'

// future weather layers from aeris
export const FUTURE_WEATHER_LAYERS = Object.freeze([
  'frad-hrrr',
  'ftemps-hrrr',
  'fwinds-hrrr'
])

const mapBoxToken =
  'pk.eyJ1IjoiYWdyaWJsZSIsImEiOiJjaW1ubDBxeDMwMGpidTdsdmQwanExMDJ4In0.jUZhBfDP_3zEWdUUWCbQ5w'

export const BASEMAP_OPTIONS = Object.freeze([
  {
    label: 'Satellite',
    value: `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/256/{z}/{x}/{y}?access_token=${mapBoxToken}`
  },
  {
    label: 'Streets',
    value: `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/256/{z}/{x}/{y}?access_token=${mapBoxToken}`
  },
  {
    label: 'Terrain',
    value: `https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/tiles/256/{z}/{x}/{y}?access_token=${mapBoxToken}`
  }
])

export const NATIONAL_RAINFALL_DIVISIONS = Object.freeze([
  { label: 'State', value: 'states' },
  { label: 'ASD', value: 'asds' },
  { label: 'County', value: 'counties' }
])

export const NATIONAL_RAINFALL_TIMEFRAMES = Object.freeze([
  { label: 'Last 24 hours', value: 'dp' },
  { label: 'Last 7 Days', value: 'wp' },
  { label: 'Last 30 days', value: 'mp' },
  { label: 'Year to Date', value: 'yp' }
])

export const NATIONAL_RAINFALL_COLORS = Object.freeze([
  { id: 0, label: 'None', color: 'rgb(102, 102, 102)' },
  { id: 1, label: 'Light', color: 'rgb(51,145,188)' },
  { id: 2, label: 'Medium', color: 'rgb(42,101,172)' },
  { id: 3, label: 'Heavy', color: 'rgb(37,51,148)' },
  { id: 4, label: 'Critical', color: 'rgb(10,21,101)' }
])

export const AERIS_WEATHER_VARIABLES = Object.freeze([
  { label: 'Rain', value: 'radar' },
  { label: 'Temp', value: 'temperatures' },
  { label: 'Wind', value: 'winds' }
])

export const AERIS_WEATHER_TIMEFRAMES = Object.freeze([
  { label: 'Past', value: 'past' },
  { label: 'Future', value: 'future' }
])

export const AERIS_VARIABLE_LOOKUP = Object.freeze({
  radar: {
    past: 'radar',
    future: 'frad-hrrr'
  },
  temperatures: {
    past: 'temperatures',
    future: 'ftemps-hrrr'
  },
  winds: {
    past: 'winds',
    future: 'fwinds-hrrr'
  }
})

export const RADAR_COLOR_RAMPS = Object.freeze({
  radar: 'colorRampRain',
  'frad-hrrr': 'colorRampRain',
  temperatures: 'colorRampTemp',
  'ftemps-hrrr': 'colorRampTemp',
  winds: 'colorRampWind',
  'fwinds-hrrr': 'colorRampWind'
})

// Map Sources
export const NATIONAL_RAINFALL_SOURCE = 'nationalRainfallSource'

export const WEATHER_AGGREGATION_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://weatheragg.nutrienagsolutions.com'
    : 'https://weatheragg.dev.nutrienagsolutions.com'

export const mapIcons = Object.freeze({
  completeDrawing
})

export const POINTER_MOVE = 'pointermove'
export const DRAW_END = 'drawend'

export const ENTITY_TYPE_ACCOUNT = 'account'
export const ENTITY_TYPE_ORG_GROUP = 'org_group'
export const ENTITY_TYPE_ORGS = 'orgs'
export const ENTITY_TYPE_ENTERPRISE_GROUPS = 'enterprise_groups'
export const ENTITY_TYPE_FARMS = 'farms'
export const ENTITY_TYPE_FIELDS = 'fields'

export const METER_TO_ACRES_CONVERSION_FACTOR = 0.000247105

export const FEATURE_COLLECTION = 'featurecollection'
