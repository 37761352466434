import { useEffect } from 'react'
import Select from 'ol/interaction/Select'

let select
const useFeatureSelect = ({ map, layers, onSelect, selectOptions }) => {
  /*
    map - ol Map class
    layers - array of layer names that are selectable
    onSelect - callback function that returns ol Select interaction
    selectOptions - additional options which can be passed into ol Select interaction
  */
  useEffect(() => {
    if (map) {
      select = new Select({
        layers: layer => {
          return layers.includes(layer.getProperties().name)
        },
        ...selectOptions
      })
      map.addInteraction(select)
      if (onSelect) select.on('select', () => onSelect(select))
    }
  }, [layers, map, onSelect, selectOptions])
  return select
}

export default useFeatureSelect
