import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Button from '@nutrien/uet-react/Button'
import Box from '@nutrien/uet-react/Box'
import round from 'lodash/round'
import FieldThumbnail from 'components/GeoSpatial/components/FieldThumbnail'
import usePermissions from '../../../../hooks/usePermissions'
import { FARM_TREE_PRODUCT } from '../../../../helpers/products'
import RenameSection from '../RenameSection'
import EditSection from '../EditSection'
import MoveSection from '../MoveSection'

const EditField = ({
  field,
  parentIdState,
  nameState,
  onClickDelete,
  setParentId,
  setName
}) => {
  const { t } = useTranslation()
  const { canChange, canDelete } = usePermissions(FARM_TREE_PRODUCT)

  const fieldAcreage = useMemo(() => round(field.acreage, 2), [field.acreage])

  return (
    <>
      <EditSection name={t('Boundary')} details={`${fieldAcreage} acres`}>
        <Box
          height={150}
          width="100%"
          position="relative"
          bgcolor="grey.50"
          display="flex"
          justifyContent="center"
          alignItems="center">
          <FieldThumbnail fieldGeometry={field.poly} extent={field.extent} />
        </Box>
      </EditSection>
      {canChange(field.uuid) && (
        <>
          <RenameSection name={nameState} type={t('field')} setName={setName} />
          <MoveSection
            name={field.name}
            parentId={parentIdState}
            setParentId={setParentId}
          />
        </>
      )}
      {canDelete(field.uuid) && (
        <EditSection
          noButton
          name={t('delete') + ' ' + t('field')}
          details={
            field.canDelete
              ? t('delete_field_warning', { name: field.name })
              : t('fields_deletion_blurb')
          }>
          {field.canDelete && (
            <Button
              onClick={onClickDelete}
              variant="outlined"
              disabled={!field.canDelete}>
              {t('delete')}
            </Button>
          )}
        </EditSection>
      )}
    </>
  )
}

export default EditField

EditField.propTypes = {
  field: PropTypes.object.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  setParentId: PropTypes.func.isRequired,
  parentIdState: PropTypes.string.isRequired,
  nameState: PropTypes.string.isRequired,
  setName: PropTypes.func.isRequired
}
