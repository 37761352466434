import { createSelector } from 'reselect'
import getSearchedEntities from './getSearchedEntities'
import getShowableIds from './getShowableIds'

const getSearchedEntitiesHierarchy = createSelector(
  [getSearchedEntities, getShowableIds],
  (searchedEntities, hierarchicalEntities) => {
    if (searchedEntities && hierarchicalEntities) {
      const searchedHierarchicalEntities = hierarchicalEntities.reduce(
        (previous, uuid) => {
          if (searchedEntities.includes(uuid)) previous.push(uuid)
          return previous
        },
        []
      )
      return searchedHierarchicalEntities
    }
    return null
  }
)

export default getSearchedEntitiesHierarchy
