import { useCallback } from 'react'
import _some from 'lodash/some'
import _includes from 'lodash/includes'
import { useSelector } from 'react-redux'
import { ENTITY_TYPE_ORGS } from 'const'

import { selectFieldById, selectGroupsFor } from 'store/selectors/farmTree'

/**
 * Effect returning functions check permissions of a given tree node upon a product
 * Organizations don't have permissions: their premissions depends on their direct children.
 * Other nodes have permissions attached
 *
 * @param {string} product - checked product slug
 * @returns {object} with 4 functions: canView, canAdd, canChange and canDelete
 * Each takes entity id and returning boolean:
 * @example usePermissions('field-forecast').canView(entityId)
 */
export default product => {
  const state = useSelector(state => state)

  const hasPermission = useCallback(
    permission => entityId => {
      // TODO: this selector is misnamed, as it can return any node
      const entity = selectFieldById(state, entityId)
      if (!entity) return false
      const nodes =
        entity.type === ENTITY_TYPE_ORGS
          ? selectGroupsFor(state, entityId)
          : [entity]
      return _some(nodes, node =>
        _includes(node.permissions, `${product}:${permission}`)
      )
    },
    [state, product]
  )

  return {
    canView: hasPermission('view'),
    canAdd: hasPermission('add'),
    canChange: hasPermission('change'),
    canDelete: hasPermission('delete')
  }
}
