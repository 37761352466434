import { useMemo } from 'react'
import { COUNTRIES, MAIN_COUNTRIES } from 'const'
import useFlags from '../useFlags'

export default () => {
  const flags = useFlags()

  const countries = useMemo(() => {
    if (flags.auSupport) {
      return MAIN_COUNTRIES
    } else {
      return MAIN_COUNTRIES.filter(x => x.value !== COUNTRIES.AU)
    }
  }, [flags.auSupport])

  return countries
}
