import PropTypes from 'prop-types'
import React from 'react'
import Box from '@nutrien/uet-react/Box'
import { useTranslation } from 'react-i18next'
import {
  ENTITY_TYPE_FIELDS,
  ENTITY_TYPE_FARMS,
  ENTITY_TYPE_ENTERPRISE_GROUPS,
  ENTITY_TYPE_ORGS
} from 'components/GeoSpatial/constants'
import EditField from './components/EditField'
import EditFarm from './components/EditFarm'
import RenameSection from './components/RenameSection'

const EditEntity = ({
  entity,
  entityChildren,
  onClickDelete,
  setName,
  setParentId,
  parentIdState,
  nameState
}) => {
  const { t } = useTranslation()
  const getComponent = () => {
    if (entity.type === ENTITY_TYPE_FIELDS) {
      return (
        <EditField
          field={entity}
          nameState={nameState}
          onClickDelete={onClickDelete}
          parentIdState={parentIdState}
          setName={setName}
          setParentId={setParentId}
        />
      )
    }
    if (entity.type === ENTITY_TYPE_FARMS) {
      return (
        <EditFarm
          farm={entity}
          farmChildren={entityChildren}
          nameState={nameState}
          onClickDelete={onClickDelete}
          setName={setName}
        />
      )
    }

    if (entity.type === ENTITY_TYPE_ENTERPRISE_GROUPS) {
      return (
        <RenameSection name={nameState} setName={setName} type={t('group')} />
      )
    }

    if (entity.type === ENTITY_TYPE_ORGS) {
      return (
        <RenameSection
          name={nameState}
          setName={setName}
          type={t('organization')}
        />
      )
    }
  }

  return <Box p={2}>{getComponent()}</Box>
}

export default EditEntity

EditEntity.propTypes = {
  entity: PropTypes.object.isRequired,
  entityChildren: PropTypes.array,
  nameState: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  parentIdState: PropTypes.string,
  setName: PropTypes.func.isRequired,
  setParentId: PropTypes.func.isRequired
}
