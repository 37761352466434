import MultiPolygon from 'ol/geom/MultiPolygon'
import LinearRing from 'ol/geom/LinearRing'
import polygonContainsPolygon from '../polygonContainsPolygon'

/*
    Checks whether Polygon contains another Polygon and returns
    MultiPolygon with hole cut out if containerPolygon completely contains hole

    containerPolygon: ol Polygon geometry
    holeCandidate: ol Polygon geometry
*/

const subtractPolygon = ({ containerPolygon, holeCandidate }) => {
  if (polygonContainsPolygon(containerPolygon, holeCandidate)) {
    // create LinearRing which is used as the hole for containerPolygon if it contains holeCandidate
    const linearRing = new LinearRing(holeCandidate.getCoordinates()[0])
    containerPolygon.appendLinearRing(linearRing)
    const featureWithHole = new MultiPolygon([
      containerPolygon.getCoordinates()
    ])
    return featureWithHole
  }
  return undefined
}

export default subtractPolygon
