import React from 'react'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'
import Button from '@nutrien/uet-react/Button'
import './DrawButton.scss'

const DrawButton = props => {
  const { drawingOn, onClick, disabled } = props
  const { t } = useTranslation('geospatial')
  const translatedButtonText = drawingOn ? t('Cancel') : t('Draw')
  return (
    <Button
      className="drawButton"
      variant="outlined"
      onClick={onClick}
      disabled={disabled}>
      {translatedButtonText}
    </Button>
  )
}

DrawButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  drawingOn: PropTypes.bool.isRequired
}

export default DrawButton
