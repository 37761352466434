import { castToUTC } from 'helpers/date'

export const LOGOUT_MUTATION = `
  mutation Logout {
    logout
  }
`

export const USER_OBJECT = `{
  id
  firstName
  lastName
  email
  profile {
    language
    unit
    dailyEmailReport
    intercomHmac
    agribleTermsAndConditions
    address {
      uuid
      name
      addressLine1
      addressLine2
      city
      state
      zipCode
      country
      phoneNumber
    }
    ownerOrganizations {
      uuid
      name
    }
  }
  documentationLinks {
    key
    value
  }
  subscriptions {
    uuid
    planName
    services {
      slug
      defaultProduct
      visible
    }
    organizationUuid
    organizationName
    activatedDate
    expirationDate
  }
  communityAdminAccess
  communityDashboardAccess {
    canViewStakeholderDashboard
    canViewEmployeeDashboard
    canManageOneOrMoreCampaigns
    canManageMembers
  }
}`

export const USER_QUERY = `
  query User {
    currentUser ${USER_OBJECT}
  }
`

const unexpectedError = 'an_unexpected_error_occurred'

class Authentication {
  constructor(graphqlClient) {
    // initial setting: reuse stored token
    graphqlClient.setHeader('Authorization', `Bearer ${this.accessToken()}`)

    graphqlClient.enableAuthRenewal(async () => {
      try {
        await this.sendAuthRequest({
          grant_type: 'refresh_token',
          refresh_token: localStorage.getItem('refresh_token')
        })
      } catch {
        this.clearStorage()
        this.onExpiredAuth()
      }
    })
    this.logout = this.logout.bind(this)
    this.getCurrentUser = this.getCurrentUser.bind(this)
    this.exchangeSSOToken = this.exchangeSSOToken.bind(this)
    this.graphqlClient = graphqlClient
  }

  async login({ username, password }) {
    await this.sendAuthRequest({
      grant_type: 'password',
      password: password.trim(),
      username: username.trim()
    })
    return { username }
  }

  async logout() {
    this.clearStorage()
    const result = await this.graphqlClient.request({
      query: LOGOUT_MUTATION
    })
    return result.data.logout
  }

  isAuthenticated() {
    try {
      const expiresAt = parseInt(localStorage.getItem('expires_at'), 10)
      const now = castToUTC(new Date())
      return new Date(now).getTime() < expiresAt
    } catch (err) {
      // unparseable state: log out
      this.logout()
      return false
    }
  }

  accessToken() {
    try {
      return localStorage.getItem('access_token')
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('Cookies Required')
    }
  }

  async getCurrentUser() {
    const userData = await this.graphqlClient.request({
      query: USER_QUERY
    })

    return userData.data.currentUser
  }

  clearStorage() {
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('expires_at')
    localStorage.removeItem('authType')
  }

  async exchangeSSOToken(auth0AccessToken) {
    const { authAuthority } = window.uiConf
    const response = await fetch(
      `${authAuthority}/nutrien/users/v3/exchange-token/`,
      {
        headers: {
          Authorization: `AccessToken ${auth0AccessToken}`
        },
        method: 'PATCH'
      }
    )
    if (!response.ok) throw Error(unexpectedError)

    const authResult = await response.json()
    if (authResult.is_agreed_agrible_com && authResult.country) {
      this.setToken(authResult.token)
    }
    return authResult
  }

  async submitAgreeAgribleCom(country, auth0AccessToken) {
    const { authAuthority } = window.uiConf
    const response = await fetch(`${authAuthority}/nutrien/users/v3/`, {
      body: JSON.stringify({
        country: country,
        is_agreed_agrible_com: true
      }),
      headers: {
        Authorization: `AccessToken ${auth0AccessToken}`,
        'Content-type': 'application/json'
      },
      method: 'PATCH'
    })

    if (!response.ok && response.status !== 400) {
      throw Error(unexpectedError)
    }

    if (response.status === 400) {
      throw Error('BAD_REQUEST')
    }

    const { token } = await response.json()
    this.setToken(token)
  }

  async sendAuthRequest(body) {
    const { clientId, authAuthority } = window.uiConf
    const response = await fetch(`${authAuthority}/o/token/`, {
      body: new URLSearchParams({ ...body, client_id: clientId }),
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/x-www-form-urlencoded'
      },
      method: 'POST'
    })

    if (!response.ok && response.status !== 400) {
      throw Error(unexpectedError)
    }

    // Validation errors are responded to with 400
    if (response.status === 400) {
      const errorResult = await response.json()
      throw Error(errorResult.error_description || unexpectedError)
    }

    const authResult = await response.json()

    if (authResult && authResult.access_token) {
      this.setToken(authResult)
    } else {
      throw Error(unexpectedError)
    }
  }

  setToken(token) {
    const now = castToUTC(new Date())
    localStorage.setItem('access_token', token.access_token)
    localStorage.setItem('refresh_token', token.refresh_token)
    localStorage.setItem(
      'expires_at',
      token.expires_in * 1000 + new Date(now).getTime()
    )
    // update token used
    this.graphqlClient.setHeader(
      'Authorization',
      `Bearer ${token.access_token}`
    )
  }
}

export default Authentication
