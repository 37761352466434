import { useCallback } from 'react'
import {
  ACRE_TO_HECTARE_RATIO,
  AREA_UNITS,
  AREA_UNITS_FULL,
  UNITS
} from 'const'
import useUnit from '../useUnit'

/**
 * Converts acreage to the user's selected units.
 * @param {number} acreage  - the acreage that needs to be converted
 * @return {function} - returns a function that will return a object that contains areaValue and areaUnit
 */
const useAcreageConverter = (useFullUnits = false) => {
  const unit = useUnit() ?? UNITS.IMPERIAL

  const converter = useCallback(
    acreage => {
      let areaValue = acreage

      if (unit === UNITS.METRIC) {
        areaValue = acreage * ACRE_TO_HECTARE_RATIO
      }

      return {
        areaValue: Math.round(areaValue),
        areaUnit: useFullUnits ? AREA_UNITS_FULL[unit] : AREA_UNITS[unit]
      }
    },
    [unit, useFullUnits]
  )

  return converter
}

/**
 * Variation that may be better for certain implementations
 * @param {boolean} useFullUnits
 * @returns {[function, string]}
 */
export const useAreaUnitAndConverter = (useFullUnits = false) => {
  const unit = useUnit()
  const converter = useCallback(
    acreage => {
      const areaValue =
        unit === UNITS.METRIC ? acreage * ACRE_TO_HECTARE_RATIO : acreage
      return Math.round(areaValue)
    },
    [unit]
  )
  const unitString = useFullUnits ? AREA_UNITS_FULL[unit] : AREA_UNITS[unit]
  return [converter, unitString]
}

export default useAcreageConverter
