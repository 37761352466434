import { lazy } from 'react'
const routes = [
  {
    ID: 'sustainabilityDashboard',
    path: '/stakeholder-dashboard',
    name: 'Stakeholder Dashboard',
    View: lazy(() => import('./StakeholderDashboard')),
    authorizedRoles: []
  },
  {
    ID: 'sustainabilityDashboard.publicRoute',
    path: '/stakeholder-dashboard/public-route',
    name: 'Public Route Example',
    View: lazy(() => import('./PublicRoute')),
    authorizedRoles: ['allowAnonymous']
  },
  {
    ID: 'sustainabilityDashboard.redirect',
    path: '/stakeholder-dashboard/redirect',
    redirectTo: '/stakeholder-dashboard',
    authorizedRoles: []
  }
]
export default routes
