// This is a simplified copy of @nutrien/farm-tree-navigation/src/lib/components/FarmTree/components/Entity
// Reasons: we want to override the style and force expanded to false
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import Icon from '@nutrien/uet-react/Icon'
import Box from '@nutrien/uet-react/Box'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'
import {
  UUID_MY_ACCOUNT,
  UUID_SHARED_ORGS,
  ENTITY_TYPE_ENTERPRISE_GROUPS,
  ENTITY_TYPE_ORGS,
  ENTITY_TYPE_ORG_GROUP,
  ENTITY_TYPE_FARMS,
  ENTITY_TYPE_FIELDS
} from 'const'
import DeviceHubIcon from '@nutrien/uet-react/icons/DeviceHub'
import ShareIcon from '@nutrien/uet-react/icons/Share'
import PeopleIcon from '@nutrien/uet-react/icons/People'
import BurstModeIcon from '@nutrien/uet-react/icons/BurstMode'
import ImageIcon from '@nutrien/uet-react/icons/Image'

import th from 'theme/helpers'

const StyledAccordionSummary = styled(AccordionSummary)`
  padding: ${th.spacing(0, 2)};

  ${th.breakpoints.up('sm')`
    padding: ${th.spacing(0, 3)};
  `}
`

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    '&::before': {
      position: 'relative'
    }
  },
  summaryContent: {
    margin: `${theme.spacing(1, 0)} !important`,
    display: 'flex',
    alignItems: 'center'
  },
  summaryRoot: {
    padding: theme.spacing(0, 2, 0, 1),
    backgroundColor: theme.palette.grey[50],
    marginBottom: theme.spacing(0.5),
    '&:hover': {
      backgroundColor: theme.palette.grey[100]
    },
    '&:active': {
      backgroundColor: theme.palette.grey[150]
    }
  },
  selectableArea: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: '5px'
  }
}))

const SelectedIcon = ({ uuid, type, ...props }) => {
  if (type === ENTITY_TYPE_FIELDS) {
    return <ImageIcon {...props} />
  }

  if (type === ENTITY_TYPE_FARMS) {
    return <BurstModeIcon {...props} />
  }

  if (type === ENTITY_TYPE_ORG_GROUP && uuid === UUID_SHARED_ORGS) {
    return <ShareIcon {...props} />
  }

  if (
    type === ENTITY_TYPE_ORG_GROUP ||
    type === ENTITY_TYPE_ORGS ||
    uuid === UUID_MY_ACCOUNT
  ) {
    return <DeviceHubIcon {...props} />
  }

  if (type === ENTITY_TYPE_ENTERPRISE_GROUPS) {
    return <PeopleIcon {...props} />
  }

  return <ImageIcon {...props} />
}

const EntityBox = ({ entities, handleClick }) => {
  const classes = useStyles()

  if (!entities) return null
  const { uuid, name, type } = entities
  const entityName = name
  return (
    <div
      key={uuid}
      onClick={handleClick}
      data-testid={'entity-box-click-handler'}>
      <Accordion
        square={false}
        classes={{ root: classes.root }}
        expanded={false}>
        <StyledAccordionSummary
          IconButtonProps={{ size: 'small' }}
          expandIcon={<Icon icon="expand_more" />}
          classes={{
            root: classes.summaryRoot,
            content: classes.summaryContent
          }}>
          <Box classes={{ root: classes.selectableArea }} role="button">
            <SelectedIcon
              classes={{ root: classes.icon }}
              uuid={uuid}
              type={type}
            />
            <Typography data-testid="entityBoxEntityName" variant="h5">
              {entityName}
            </Typography>
          </Box>
        </StyledAccordionSummary>
      </Accordion>
    </div>
  )
}

EntityBox.propTypes = {
  entities: PropTypes.shape({
    name: PropTypes.string,
    uuid: PropTypes.string,
    type: PropTypes.string
  })
}

export default EntityBox
