import React, { useState, useEffect, useRef } from 'react'
import { PropTypes } from 'prop-types'
import { Trans } from 'react-i18next'
import { transform } from 'ol/proj'
import scriptjs from 'scriptjs'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete'
import InputAdornment from '@nutrien/uet-react/InputAdornment'
import IconButton from '@nutrien/uet-react/IconButton'
import SearchIcon from '@nutrien/uet-react/icons/Search'
import TextField from '@nutrien/uet-react/TextField'
import Card from '@nutrien/uet-react/Card'
import Typography from '@nutrien/uet-react/Typography'
import makeStyles from '@nutrien/uet-react/styles/makeStyles'
import { MAP_PROPTYPE } from '../../constants/Geospatial'

const useStyles = makeStyles(theme => ({
  geolocationFailed: {
    position: 'absolute',
    top: theme.spacing(6),
    width: theme.spacing(27),
    background: theme.palette.error.main,
    color: theme.palette.common.white,
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  searchBar: {
    width: theme.spacing(27)
  },
  autoCompleteDropdownContainer: {
    '& > div': {
      background: theme.palette.common.white,
      borderBottom: `1px solid ${theme.palette.divider}`,
      cursor: 'pointer',
      fontSize: '10px',
      padding: theme.spacing(1),
      '&:hover': {
        background: theme.palette.grey[100]
      },
      '&.active': {
        background: theme.palette.grey[100]
      }
    }
  }
}))

const MapSearch = ({
  placeholder,
  actionButton,
  regionsOnly,
  map,
  countryCode
}) => {
  const classes = useStyles()
  const [geolocationFailed, setGeolocationFailed] = useState(false)
  const [address, setAddress] = useState('')
  const [geolocationApiLoaded, setGeolocationApiLoaded] = useState(false)

  const searchSuggestions = useRef([])

  useEffect(() => {
    scriptjs(
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyAvk7maDIY5RG5mc9VsT4oBQpoGdNQBvLI&libraries=places',
      () => setGeolocationApiLoaded(true)
    )
  }, [])

  const zoomToLocation = (addressLat, addressLon) => {
    const view = map.getView()
    view.setCenter(
      transform([addressLon, addressLat], 'EPSG:4326', 'EPSG:3857')
    )
    view.setZoom(15)
  }

  const searchClick = (lat, lon) => {
    let coordinates = false
    const postData = address
    const regex = /-?[0-9]{1,3}[.][0-9]+/
    const formValue = postData
    if (regex.test(formValue)) {
      coordinates = true
    }
    let addressLat
    let addressLon
    if (lon || coordinates) {
      if (coordinates) {
        let coords
        if (address.indexOf(',') !== -1) {
          coords = formValue.split(',')
        } else {
          formValue.split(' ')
          coords = formValue.split(/\s+/)
        }
        addressLat = parseFloat(coords[0])
        addressLon = parseFloat(coords[1])
      } else {
        addressLat = lat
        addressLon = lon
      }
    }
    if (
      addressLat > -90 &&
      addressLat < 90 &&
      addressLon > -180 &&
      addressLon < 180
    ) {
      zoomToLocation(addressLat, addressLon)
      setGeolocationFailed(false)
    } else {
      const searchAddress = searchSuggestions.current[0]?.description || address
      setAddress(address)
      geocodeByAddress(searchAddress)
        .then(results => getLatLng(results[0]))
        .then(latLng => zoomToLocation(latLng.lat, latLng.lng))
        .catch(() => setGeolocationFailed(true))
    }
  }

  const handleSelect = selectedAddress => {
    setAddress(selectedAddress)
    geocodeByAddress(selectedAddress)
      .then(results => getLatLng(results[0]))
      .then(latLng => searchClick(latLng.lat, latLng.lng))
      .catch(() => setGeolocationFailed(true))
  }

  const handleChange = newAddress => {
    setAddress(newAddress)
    setGeolocationFailed(false)
  }

  /* eslint react/prop-types: 0 */
  const renderInput = () => ({
    getInputProps,
    getSuggestionItemProps,
    suggestions
  }) => {
    if (suggestions.length > 0) {
      searchSuggestions.current = suggestions
    }
    const searchIconColor = address.length > 2 ? 'secondary' : 'disabled'
    return (
      <>
        <TextField
          {...getInputProps({
            placeholder
          })}
          InputProps={{
            startAdornment: (
              <InputAdornment>
                <IconButton onClick={searchClick} size="small" edge="start">
                  <SearchIcon color={searchIconColor} />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <div className={classes.autoCompleteDropdownContainer}>
          {suggestions.map(suggestion => (
            <div
              {...getSuggestionItemProps(suggestion)}
              key={suggestion.placeId}
              className={suggestion.active ? 'active' : undefined}>
              <span>{suggestion.description}</span>
            </div>
          ))}
        </div>
      </>
    )
  }
  const onError = () => (_status, clearSuggestions) => {
    clearSuggestions()
    searchSuggestions.current = []
  }

  if (!geolocationApiLoaded) return null
  const typeRegions = regionsOnly ? { types: ['(regions)'] } : null
  const searchOptions = {
    ...typeRegions,
    componentRestrictions: { country: countryCode }
  }
  const geolocationFailedMessage = (
    <Card classes={{ root: classes.geolocationFailed }}>
      <Typography variant="h5">No Locations Found</Typography>
      <Typography variant="body1">
        <Trans ns="geospatial">
          Your search term did not return any results.
        </Trans>
      </Typography>
    </Card>
  )
  return (
    <>
      <div className={classes.searchBar}>
        <PlacesAutocomplete
          value={address}
          onChange={handleChange}
          onSelect={handleSelect}
          shouldFetchSuggestions={address.length > 1}
          searchOptions={searchOptions}
          highlightFirstSuggestion
          onError={onError()}>
          {renderInput()}
        </PlacesAutocomplete>
      </div>
      {actionButton}
      {geolocationFailed && geolocationFailedMessage}
    </>
  )
}

MapSearch.propTypes = {
  placeholder: PropTypes.string,
  actionButton: PropTypes.node,
  regionsOnly: PropTypes.bool,
  map: PropTypes.shape(MAP_PROPTYPE).isRequired,
  // alpha-2 country code
  countryCode: PropTypes.string
}

MapSearch.defaultProps = {
  placeholder: '',
  actionButton: null,
  regionsOnly: false,
  countryCode: 'us'
}

export default MapSearch
