import { createSelector } from 'reselect'
import getSearchString from './getSearchString'
import getFlattenedEntities from './getFlattenedEntities'
import getShowableIds from './getShowableIds'
import { getEntityParents } from './getEntityParents'

const getSearchedEntities = createSelector(
  [getSearchString, getFlattenedEntities, getShowableIds],
  (searchString, flattenedEntities, showableIds) => {
    if (flattenedEntities && searchString && searchString !== '') {
      const matchingEntityUUIDs = showableIds.filter(uuid => {
        const entity = flattenedEntities[uuid]
        return entity
          ? entity.name.toLowerCase().indexOf(searchString.toLowerCase()) > -1
          : false
      })
      const matchingEntityUUIDsAndParents = []
      if (matchingEntityUUIDs.length > 0) {
        matchingEntityUUIDs.forEach(uuid => {
          matchingEntityUUIDsAndParents.push(uuid)
          const entityParents = getEntityParents(flattenedEntities, uuid, [])
          if (entityParents) {
            entityParents.forEach(parentUUID => {
              if (!matchingEntityUUIDsAndParents.includes(parentUUID))
                matchingEntityUUIDsAndParents.push(parentUUID)
            })
          }
        })
      }
      return matchingEntityUUIDsAndParents
    }
    return null
  }
)

export default getSearchedEntities
