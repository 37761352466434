import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import T from 'prop-types'
import Collapse from '@nutrien/uet-react/Collapse'
import { ENTITY_TYPE_ENTERPRISE_GROUPS } from 'const'
import useToggle from 'hooks/useToggle'
import Divider from 'components/Divider'
import Entities from '../Entities'
import HighlightedTerm from '../HighlightedTerm'
import FarmTreeListItem from '../FarmTreeListItem'
import { setSelectedId } from '../../../../redux/actions/actions'

const Entity = ({ entity, selectedId, searchString }) => {
  const [expanded, setExpanded] = useToggle(true)
  const caretDirection = expanded ? 'up' : 'down'
  const { uuid, type, children, name, poly, extent, acreage } = entity
  const dispatch = useDispatch()
  const selectId = useCallback(id => dispatch(setSelectedId(id)), [dispatch])

  const entityName = searchString ? (
    <HighlightedTerm name={name} searchString={searchString} />
  ) : (
    name
  )

  const selectThisEntity = () => selectId(uuid)
  return (
    <>
      <FarmTreeListItem
        primaryText={entityName}
        type={type}
        entity={entity}
        acreage={acreage}
        caret={children && caretDirection}
        onClick={selectThisEntity}
        onCaretClick={setExpanded}
        selected={uuid === selectedId}
        fieldProps={{ poly, extent }}
      />
      {children && (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Entities
            selectedId={selectedId}
            entities={children}
            searchString={searchString}
            type={type}
          />
        </Collapse>
      )}
      {type === ENTITY_TYPE_ENTERPRISE_GROUPS && <Divider />}
    </>
  )
}

Entity.propTypes = {
  entity: T.shape({
    name: T.string,
    uuid: T.string,
    type: T.string,
    poly: T.shape({
      type: T.string,
      coordinates: T.array
    }),
    extent: T.array,
    acreage: T.number,
    children: T.array
  }).isRequired,
  selectedId: T.string,
  searchString: T.string
}

Entity.defaultProps = {
  entity: {},
  selectedId: '',
  searchString: ''
}

export default Entity
