import {
  ENTITY_TYPE_ACCOUNT,
  ENTITY_TYPE_ORG_GROUP,
  ENTITY_TYPE_ORGS,
  ENTITY_TYPE_FARMS,
  ENTITY_TYPE_FIELDS
} from '../constants/entities'

export default currentEntity => {
  let entityType = ENTITY_TYPE_FIELDS
  if (currentEntity.org_group) {
    entityType = ENTITY_TYPE_ACCOUNT
  }
  if (currentEntity.orgs) {
    entityType = ENTITY_TYPE_ORG_GROUP
  }
  if (currentEntity.enterprise_groups) {
    entityType = ENTITY_TYPE_ORGS
  }
  if (currentEntity.farms) {
    entityType = ENTITY_TYPE_ORGS
  }
  if (currentEntity.fields) {
    entityType = ENTITY_TYPE_FARMS
  }
  return entityType
}
