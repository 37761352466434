import React, { Component } from 'react'
import T from 'prop-types'
import OlXYZ from 'ol/source/XYZ'
import OlTile from 'ol/layer/Tile'
import OlcontrolScaleLine from 'ol/control/ScaleLine'
import { transform } from 'ol/proj'
import OlMap from 'ol/Map'
import OlView from 'ol/View'
import * as Olcontrol from 'ol/control'
import 'ol/ol.css'
import MapboxIcon from '@nutrien/uet-icons/Mapbox'
import Box from '@nutrien/uet-react/Box'

import { defaults as defaultInteractions, MouseWheelZoom } from 'ol/interaction'
import CircularProgress from '@nutrien/uet-react/CircularProgress'
import Paper from '@nutrien/uet-react/Paper'
import { BASEMAP_OPTIONS } from '../../constants/Geospatial'
import { getLayerByName } from '../../utility/mapHelpers'
import StateMachineContext from '../../context/StateMachineContext'
import FullScreenIcon from '../FullScreenIcon'
import ZoomControls from '../ZoomControls'

import './BasicMap.scss'

class BasicMap extends Component {
  constructor(props) {
    super(props)
    this.mapElement = React.createRef()
    this.mouseWheelInteraction = new MouseWheelZoom()
    this.state = {
      map: null,
      loading: true
    }
  }

  componentDidMount() {
    this.constructMap()
  }

  componentDidUpdate(prevProps) {
    const { basemap, disableMouseWheelZoom } = this.props
    const { map } = this.state
    if (prevProps.basemap !== basemap) {
      const baseLayer = getLayerByName(map, 'basemap')
      map.removeLayer(baseLayer)
      baseLayer.getSource().setUrl(basemap)
      map.getLayers().insertAt(0, baseLayer)
    }
    if (prevProps.disableMouseWheelZoom !== disableMouseWheelZoom) {
      if (!disableMouseWheelZoom) {
        map.addInteraction(this.mouseWheelInteraction)
      } else {
        map.removeInteraction(this.mouseWheelInteraction)
      }
    }
  }

  constructMap = () => {
    const {
      basemap,
      stationaryMap,
      center,
      // extent,
      // extentBuffer,
      zoom,
      createMapRef,
      thumbnail,
      disableMouseWheelZoom
    } = this.props

    const baseLayer = new OlTile({
      name: 'basemap',
      source: new OlXYZ({
        url: basemap,
        maxZoom: 19,
        crossOrigin: 'anonymous',
        attributions: `© <a href="https://www.mapbox.com/map-feedback/" target="_blank">Mapbox</a>
          © <a href="https://www.openstreetmap.org/copyright" target="_blank">
          OpenStreetMap</a>
          © <a href="https://www.mapbox.com/map-feedback/" target="_blank">
          Improve this map</a>,
          Powered by <a href="https://www.aerisweather.com/" target="_blank">
          AerisWeather</a>`
      })
    })
    const attribution = new Olcontrol.Attribution({ collapsible: false })

    const map = new OlMap({
      target: this.mapElement.current,
      controls: Olcontrol.defaults({
        attribution: false,
        zoom: false,
        rotate: false
      }).extend([attribution]),
      interactions: defaultInteractions({
        dragPan: !stationaryMap && !thumbnail,
        mouseWheelZoom: !stationaryMap && !thumbnail && !disableMouseWheelZoom
      }),
      layers: [baseLayer],
      view: new OlView({
        maxZoom: 23,
        minZoom: 4,
        // extent: transformExtent(
        //   buffer(extent, extentBuffer),
        //   // transform coordinates from 4326 to 3857 spatial reference system
        //   SRS_CODES.EPSG_4326,
        //   SRS_CODES.EPSG_3857
        // ),
        center: transform(center, 'EPSG:4326', 'EPSG:3857'),
        zoom,
        constrainOnlyCenter: true
      })
    })

    if (!thumbnail) {
      map.addControl(
        new OlcontrolScaleLine({
          units: 'us'
        })
      )
    }

    this.setState({ map })

    if (createMapRef) {
      createMapRef(map)
    }

    map.once('rendercomplete', () => {
      map.updateSize()
      this.setState({ loading: false })
    })
  }

  render() {
    const {
      children,
      worldZoom,
      thumbnail,
      expandClick,
      expanded,
      center,
      showZoom
    } = this.props
    const { map, loading } = this.state
    const loadingScreen = loading ? (
      <Paper className="mapLoadingScreen">
        <CircularProgress center="xy" />
      </Paper>
    ) : null
    const mapFeatures = map ? (
      <>
        {expandClick && (
          <FullScreenIcon expanded={expanded} expandClick={expandClick} />
        )}
        {!thumbnail && showZoom && (
          <ZoomControls map={map} worldZoom={worldZoom} center={center} />
        )}
        {children}
      </>
    ) : null

    return (
      <StateMachineContext.Provider value={this.context}>
        {loadingScreen}
        <div key="map" className="map" ref={this.mapElement}>
          {mapFeatures}
          <Box
            position="absolute"
            bottom={8}
            left={8}
            zIndex={1}
            fontSize={70}
            height={52}>
            <MapboxIcon fontSize="inherit" />
          </Box>
        </div>
      </StateMachineContext.Provider>
    )
  }
}
BasicMap.propTypes = {
  children: T.node,
  basemap: T.string,
  createMapRef: T.func,
  stationaryMap: T.bool,
  center: T.arrayOf(T.number),
  extent: T.arrayOf(T.number),
  extentBuffer: T.number,
  zoom: T.number,
  worldZoom: T.bool,
  showZoom: T.bool,
  thumbnail: T.bool,
  expandClick: T.func,
  expanded: T.bool,
  disableMouseWheelZoom: T.bool
}
BasicMap.defaultProps = {
  children: null,
  basemap: BASEMAP_OPTIONS[0].value,
  createMapRef: null,
  stationaryMap: false,
  center: [-98, 40],
  extent: [-125, 24, -65, 50],
  extentBuffer: 0,
  zoom: 4,
  worldZoom: true,
  showZoom: true,
  thumbnail: false,
  expandClick: null,
  expanded: false,
  disableMouseWheelZoom: false
}
BasicMap.contextType = StateMachineContext

export default BasicMap
