// entities from the left nav
export const ENTITY_TYPE_ACCOUNT = 'account'
export const ENTITY_TYPE_ORG_GROUP = 'org_group'
export const ENTITY_TYPE_ORGS = 'orgs'
export const ENTITY_TYPE_ENTERPRISE_GROUPS = 'enterprise_groups'
export const ENTITY_TYPE_FARMS = 'farms'
export const ENTITY_TYPE_FIELDS = 'fields'

// hardcoded uuids for org_groups
export const UUID_MY_ACCOUNT = 'all-organizations'
export const NAME_MY_ACCOUNT = 'All Organizations'
export const UUID_MY_ORG = 'owned-organizations'
export const NAME_MY_ORG = 'My Organization'
export const UUID_SHARED_ORGS = 'shared-organizations'
export const NAME_SHARED_ORGS = 'Shared Organizations'

// fake temporary entity to attach a button to a virtualized menu
export const ENTITY_FAKE = 'entity-fake'

export const CHILD_TYPES = Object.freeze({
  [ENTITY_TYPE_ACCOUNT]: ENTITY_TYPE_ORG_GROUP,
  [ENTITY_TYPE_ORG_GROUP]: ENTITY_TYPE_ORGS,
  [ENTITY_TYPE_ORGS]: ENTITY_TYPE_ENTERPRISE_GROUPS,
  [ENTITY_TYPE_ENTERPRISE_GROUPS]: ENTITY_TYPE_FARMS,
  [ENTITY_TYPE_FARMS]: ENTITY_TYPE_FIELDS,
  [ENTITY_TYPE_FIELDS]: null
})

export const ENTITY_TYPE_PRETTY = Object.freeze({
  [ENTITY_TYPE_ACCOUNT]: 'Account',
  [ENTITY_TYPE_ORG_GROUP]: 'Organization Group',
  [ENTITY_TYPE_ORGS]: 'Organization',
  [ENTITY_TYPE_ENTERPRISE_GROUPS]: 'Enterprise Group',
  [ENTITY_TYPE_FARMS]: 'Farm',
  [ENTITY_TYPE_FIELDS]: 'Field'
})

export const ENTITY_TYPE_PRETTY_PLURAL = Object.freeze({
  [ENTITY_TYPE_ACCOUNT]: 'Accounts',
  [ENTITY_TYPE_ORG_GROUP]: 'Organization Groups',
  [ENTITY_TYPE_ORGS]: 'Organizations',
  [ENTITY_TYPE_ENTERPRISE_GROUPS]: 'Enterprise Groups',
  [ENTITY_TYPE_FARMS]: 'Farms',
  [ENTITY_TYPE_FIELDS]: 'Fields'
})

// api endpoints
export const API_PATH_EDIT_FIELD = 'fields'
export const API_PATH_EDIT_FARM = 'farms'
export const API_PATH_EDIT_EG = 'enterprise/mfrgroups'
export const API_PATH_EDIT_ORG = 'enterprise/orgs'

// icons
export const ENTITY_ICONS = Object.freeze({
  [ENTITY_TYPE_ACCOUNT]: 'organization',
  [ENTITY_TYPE_ORG_GROUP]: 'organization',
  [ENTITY_TYPE_ORGS]: 'organization',
  [ENTITY_TYPE_ENTERPRISE_GROUPS]: 'group',
  [ENTITY_TYPE_FARMS]: 'farm',
  [ENTITY_TYPE_FIELDS]: 'field'
})

// list of magic entities
export const MAGIC_ENTITIES = Object.freeze([
  UUID_MY_ACCOUNT,
  UUID_MY_ORG,
  UUID_SHARED_ORGS
])

export const EDIT_ENTITY_CONTROLS = Object.freeze({
  [ENTITY_TYPE_ORGS]: ['rename'],
  [ENTITY_TYPE_ENTERPRISE_GROUPS]: ['rename'],
  [ENTITY_TYPE_FARMS]: ['rename', 'delete'],
  [ENTITY_TYPE_FIELDS]: ['rename', 'move', 'delete']
})

// if this can be replaced for ENTITY_ICONS or other pre-existing values, switch it out
export const EDIT_ENTITY_SIMPLE_NAMES = Object.freeze({
  [ENTITY_TYPE_ORGS]: 'organization',
  [ENTITY_TYPE_ENTERPRISE_GROUPS]: 'organization',
  [ENTITY_TYPE_FARMS]: 'farm',
  [ENTITY_TYPE_FIELDS]: 'field'
})
