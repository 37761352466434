import { useEffect } from 'react'
import PropTypes from 'prop-types'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import TopoJSON from 'ol/format/TopoJSON'
import Stroke from 'ol/style/Stroke'
import Style from 'ol/style/Style'
import Fill from 'ol/style/Fill'

export const TOPO_COUNTRY_LAYER_NAME = 'countryBoundary'
const DATA_PROJECTION_TYPE = 'EPSG:4326' // World Geodetic System 1984
const FEATURE_PROJECTION_TYPE = 'EPSG:3857' // Pseudo-Mercator

const layerStyle = new Style({
  stroke: new Stroke({
    // outline of country
    color: 'rgba(255,255,255,0.2)',
    width: 1
  }),
  fill: new Fill({
    // nearly transparent fill to allow forEachLayerAtPixel to read feature when drawing
    // this needs to be kept at 0.004 specifically to ensure the boundary is visible enough to be read on iOS
    color: 'rgba(255,255,255,0.004)'
  })
})

// Stores vector source object per country
// For unknown reasons, after closing a map, any subsequent opening will result in misformated
// TopoJSON features.
// new TopoJSON().readFeatures() returns inconsistent results, despite being passed the same
// topoMap object
const sourcePerCountry = {}

async function countryBoundaryLayer(map, country, onAddingBoundary) {
  try {
    let source = sourcePerCountry[country]
    if (!source) {
      // dynamically import topoJSON
      const topoMap = await import(`assets/${country}.topo.json`)
      source = new VectorSource({
        features: new TopoJSON().readFeatures(topoMap, {
          dataProjection: DATA_PROJECTION_TYPE,
          featureProjection: FEATURE_PROJECTION_TYPE
        })
      })
      sourcePerCountry[country] = source
    }
    const countryBoundaryVector = new VectorLayer({
      name: TOPO_COUNTRY_LAYER_NAME,
      className: TOPO_COUNTRY_LAYER_NAME,
      source,
      style: layerStyle
    })
    map.addLayer(countryBoundaryVector)
    map.getView().fit(source.getExtent(), map.getSize())
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(`failed to load boundary for ${country}`, err)
    onAddingBoundary(false)
  }
}

function CountryBoundary({ map, country, onAddingBoundary }) {
  useEffect(() => {
    countryBoundaryLayer(map, country, onAddingBoundary)
  }, [country, map, onAddingBoundary])
  return null
}

CountryBoundary.propTypes = {
  map: PropTypes.shape({
    addLayer: PropTypes.func
  }).isRequired,
  country: PropTypes.string,
  onAddingBoundary: PropTypes.func
}

CountryBoundary.defaultProps = {
  country: undefined,
  onAddingBoundary: undefined
}

export default CountryBoundary
