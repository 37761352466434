import * as ActionTypes from '../actions/constants'
import {
  NAME_MY_ACCOUNT,
  ENTITY_TYPE_ACCOUNT,
  UUID_MY_ACCOUNT,
  ENTITY_TYPE_ORG_GROUP,
  UUID_MY_ORG
} from '../../constants/entities'
import flattenEntities, { resetEntities } from '../../utility/flattenEntities'

const initialState = {
  entities: null,
  error: null,
  isLoading: false,
  isEditing: false,
  isEditDialogOpen: false,
  isOpen: false,
  searchString: '',
  selectedId: null,
  openedIds: [],
  nestedEntities: null
}

const setSelectedId = (state, action) => ({
  ...state,
  selectedId: action.payload
})

const setOpenEntities = (state, action) => ({
  ...state,
  openedIds: action.payload
})

const setSearchString = (state, action) => ({
  ...state,
  searchString: action.payload
})

const initiateEntityRequest = state => ({
  ...state,
  isLoading: true
})

const receiveEntitiesSuccess = (state, action) => {
  const { name, uuid } = action.payload[0]
  resetEntities()
  return {
    ...state,
    entities: flattenEntities({
      entitiesObj: {
        name: NAME_MY_ACCOUNT,
        type: ENTITY_TYPE_ACCOUNT,
        uuid: UUID_MY_ACCOUNT,
        org_group: [
          {
            name,
            type: ENTITY_TYPE_ORG_GROUP,
            uuid: UUID_MY_ORG,
            orgs: action.payload
          }
        ]
      }
    }),
    selectedId: uuid,
    isLoading: false,
    error: null
  }
}

const receiveEntitiesError = (state, action) => ({
  ...state,
  isLoading: false,
  error: action.payload
})

const toggleFarmTreeVisibility = state => ({
  ...state,
  isOpen: !state.isOpen
})

const toggleEdit = state => ({
  ...state,
  isEditing: !state.isEditing
})

const toggleEditDislog = state => ({
  ...state,
  isEditDialogOpen: !state.isEditDialogOpen
})

const FarmTree = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_SELECTED_ID:
      return setSelectedId(state, action)
    case ActionTypes.SET_OPEN_ENTITIES:
      return setOpenEntities(state, action)
    case ActionTypes.SET_SEARCH_STRING:
      return setSearchString(state, action)
    case ActionTypes.TOGGLE_FARM_TREE:
      return toggleFarmTreeVisibility(state)
    case ActionTypes.TOGGLE_EDIT:
      return toggleEdit(state)
    case ActionTypes.TOGGLE_EDIT_DIALOG:
      return toggleEditDislog(state)
    case ActionTypes.REQUEST_ENTITIES:
      return initiateEntityRequest(state)
    case ActionTypes.RECEIVE_ENTITIES_SUCCESS:
      return receiveEntitiesSuccess(state, action)
    case ActionTypes.RECEIVE_ENTITIES_ERROR:
      return receiveEntitiesError(state, action)
    default:
      return state
  }
}

export default FarmTree
