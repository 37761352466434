import { createSelector } from 'reselect'
import get from 'lodash/get'
import getSelectedId from './getSelectedId'
import getFlattenedEntities from './getFlattenedEntities'

const getSelectedEntity = createSelector(
  [getFlattenedEntities, getSelectedId],
  (entities, selectedId) => get(entities, selectedId)
)

export default getSelectedEntity
