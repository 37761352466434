import { fromCircle } from 'ol/geom/Polygon'
import { GEOMETRY_CIRCLE } from '../../constants/geometry'

// takes an openlayers feature and returns the geometry.
// if feature is a circle it will convert to polygon.

const getNonCircleFeatureGeometry = feature => {
  const featureGeometry =
    feature.getGeometry().getType() === GEOMETRY_CIRCLE
      ? fromCircle(feature.getGeometry())
      : feature.getGeometry()
  return featureGeometry
}

export default getNonCircleFeatureGeometry
