/* eslint-disable no-unused-vars */
import toggleArrayOfText from '../../utility/toggleArrayOfText'

import { getEntityParent, getSelectedId } from '../../selectors'
import * as actionTypes from './constants'

export function initiateEntityRequest() {
  return {
    type: actionTypes.REQUEST_ENTITIES
  }
}
export function receiveEntitiesSuccess(data) {
  return {
    payload: data,
    type: actionTypes.RECEIVE_ENTITIES_SUCCESS
  }
}
export function receiveEntitiesError(data) {
  return {
    payload: data,
    type: actionTypes.RECEIVE_ENTITIES_ERROR
  }
}
export function setSelectedId(id) {
  return (dispatch, getState) => {
    const selectedId = getSelectedId(getState())

    if (id !== selectedId) {
      dispatch({
        payload: id,
        type: actionTypes.SET_SELECTED_ID
      })
    }
  }
}

export function setSearchString(searchString) {
  return {
    payload: searchString,
    type: actionTypes.SET_SEARCH_STRING
  }
}
export function setOpenEntities(arrayOfIds) {
  return {
    payload: arrayOfIds,
    type: actionTypes.SET_OPEN_ENTITIES
  }
}

export function initializeTreeOpenIds(activeId) {
  return (dispatch, getState) => {
    const state = getState()
    const freshOpenedIds = [activeId, ...getEntityParent(state)]
    return dispatch(setOpenEntities(freshOpenedIds))
  }
}

export function toggleOpenId(entityUuid) {
  return (dispatch, getState) => {
    const state = getState()
    const currentOpenItems = state.farmTree.openedIds
    const updatedOpenIds = toggleArrayOfText(entityUuid, currentOpenItems)
    return dispatch(setOpenEntities(updatedOpenIds))
  }
}

export function toggleFarmTreeVisibility() {
  return {
    type: actionTypes.TOGGLE_FARM_TREE
  }
}

export function toggleEdit() {
  return {
    type: actionTypes.TOGGLE_EDIT
  }
}

export function toggleEditDialog() {
  return {
    type: actionTypes.TOGGLE_EDIT_DIALOG
  }
}

export function updateEntity(updateEntityFunction, payload) {
  return (dispatch, getState) => {
    const state = getState()
    const { activeId } = state.farmTree
    return dispatch(updateEntityFunction(activeId, payload)).then(
      // waiting for api support
      response => () => {},
      // eslint-disable-next-line handle-callback-err
      err => () => {}
    )
  }
}

export function deleteEntity(deleteEntityFunction) {
  return (dispatch, getState) => {
    const state = getState()
    const { activeId } = state.farmTree
    return dispatch(deleteEntityFunction(activeId)).then(
      // waiting for api support
      response => () => {},
      // eslint-disable-next-line handle-callback-err
      err => () => {}
    )
  }
}
