import * as actionTypes from './actionTypes'

export function setBasemap(data, label) {
  return {
    payload: {
      data,
      label
    },
    type: actionTypes.SET_BASEMAP
  }
}
export function setRadarTimeframe(data, label) {
  return {
    payload: {
      data,
      label
    },
    type: actionTypes.SET_RADAR_TIMEFRAME
  }
}
export function setRadarVariable(data, label) {
  return {
    payload: {
      data,
      label
    },
    type: actionTypes.SET_RADAR_VARIABLE
  }
}
export function toggleRadarLayers() {
  return {
    type: actionTypes.TOGGLE_RADAR_LAYER
  }
}
export function setRainfallDivision(data, label) {
  return {
    payload: {
      data,
      label
    },
    type: actionTypes.SET_RAINFALL_DIVISION
  }
}
export function setRainfallTimeframe(data, label) {
  return {
    payload: {
      data,
      label
    },
    type: actionTypes.SET_RAINFALL_TIMEFRAME
  }
}

export function toggleRainfallLayers() {
  return {
    type: actionTypes.TOGGLE_RAINFALL_LAYER
  }
}

export function setPopupData(feature) {
  return {
    payload: {
      feature
    },
    type: actionTypes.SET_POPUP_DATA
  }
}

export function clearPopupData() {
  return {
    type: actionTypes.CLEAR_POPUP_DATA
  }
}

export function saveFieldCoordinates(data) {
  return {
    payload: data,
    type: actionTypes.SAVE_FIELD_COORDINATES
  }
}

export function setMapZoom(data) {
  return {
    payload: data,
    type: actionTypes.SET_MAP_ZOOM
  }
}
