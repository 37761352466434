import { ENTITY_TYPE_FIELDS } from '../constants/entities'

import getEntityType from './getEntityType'
import getEntitiesList from './getEntitiesList'

let entitiesFlattened = {}
export const resetEntities = () => {
  entitiesFlattened = {}
}

function flattenEntities({ entitiesObj }) {
  const entities =
    entitiesObj.type !== ENTITY_TYPE_FIELDS ? getEntitiesList(entitiesObj) : []
  if (!entitiesFlattened[entitiesObj.uuid] && entitiesObj.uuid) {
    const childrenUUIDs = entities.map(entity => entity.uuid)
    let childrenUUIDsObj
    if (childrenUUIDs.length > 0) {
      childrenUUIDsObj = { childrenUUIDs }
    }
    entitiesFlattened[entitiesObj.uuid] = {
      type: getEntityType(entitiesObj),
      ...childrenUUIDsObj,
      ...entitiesObj
    }
    if (entitiesFlattened[entitiesObj.uuid].fields)
      delete entitiesFlattened[entitiesObj.uuid].fields
    if (entitiesFlattened[entitiesObj.uuid].farms)
      delete entitiesFlattened[entitiesObj.uuid].farms
    if (entitiesFlattened[entitiesObj.uuid].enterprise_groups)
      delete entitiesFlattened[entitiesObj.uuid].enterprise_groups
    if (entitiesFlattened[entitiesObj.uuid].orgs)
      delete entitiesFlattened[entitiesObj.uuid].orgs
    if (entitiesFlattened[entitiesObj.uuid].org_group)
      delete entitiesFlattened[entitiesObj.uuid].org_group
  }
  if (entities.length > 0) {
    entities.forEach(entity => {
      flattenEntities({
        entitiesObj: entity
      })
    })
  }
  return entitiesFlattened
}

export default flattenEntities
