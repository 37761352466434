import { polygon } from '@turf/helpers'
import contains from '@turf/boolean-contains'

/*
    Checks whether if one polygon is completely contained inside another
    Returns true or false based on if this condition is met

    polygon1: ol Polygon geometry
    polygon2: ol Polygon geometry
*/

const polygonContains = (polygon1, polygon2) =>
  contains(
    polygon(polygon1.getCoordinates()),
    polygon(polygon2.getCoordinates())
  )

export default polygonContains
