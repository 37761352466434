import { useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { UUID_MY_ACCOUNT } from 'const'
import { getChildren } from 'components/FarmTree/selectors/getNestedActiveEntityChildren'
import { SET_SELECTED_ID } from 'components/FarmTree/redux/actions/constants'
import { setSelectedId } from 'components/FarmTree/redux/actions/actions'

import { selectFarmTree } from 'store/selectors/farmTree'

function usePopulateFarmTree() {
  const flattenedEntities = useSelector(selectFarmTree)
  const activeId = useSelector(state => state.farmTree.activeId)
  const selectedId = useSelector(state => state.farmTree.selectedId)
  const previousSelectedId = useSelector(
    state => state.farmTree.previousSelectedId
  )
  const { farmItemId } = useParams()
  const dispatch = useDispatch()

  // Convert flattenedEntities into a tree
  const entities = useMemo(() => {
    if (!flattenedEntities[UUID_MY_ACCOUNT]) return {}

    return getChildren(flattenedEntities[UUID_MY_ACCOUNT], flattenedEntities)
  }, [flattenedEntities])

  // Url sync
  useEffect(() => {
    if (farmItemId && selectedId !== farmItemId) {
      // Support browser back/forward
      dispatch(setSelectedId(farmItemId))
    } else if (!selectedId) {
      dispatch(setSelectedId(activeId))
    }
  }, [dispatch, farmItemId, selectedId, activeId])

  // Ensure diff between previousSelectedId and selectedId is only temporary
  useEffect(() => {
    if (previousSelectedId && selectedId && selectedId !== previousSelectedId) {
      dispatch({ id: selectedId, type: SET_SELECTED_ID })
    }
  }, [dispatch, previousSelectedId, selectedId])

  return {
    flattenedEntities,
    previousSelectedId,
    selectedId,
    entities
  }
}

export default usePopulateFarmTree
