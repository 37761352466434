import subtractPolygon from '../subtractPolygon'
import {
  GEOMETRY_MULTIPOLYGON,
  GEOMETRY_POLYGON
} from '../../constants/geometry'

/*
    Searches for any features within a layer that hole candidate may be contained within,
    and if condition is met, cuts the hole candidate out of the feature geometry and returns the
    new MultiPolygon geometry with a hole

    layer: ol Vector layer
    holeCandidate: ol Polygon geometry
*/

const drawHoleInLayer = ({ layer, holeCandidate }) => {
  let featureWithHole
  const layerFeatures = layer.getSource().getFeatures()
  // check all features in layer to see if they contain the holeCandidate
  for (let i = 0; i < layerFeatures.length; i += 1) {
    const geometryType = layerFeatures[i].getGeometry().getType()
    let drawLayerFeaturePolygons = []
    if (geometryType === GEOMETRY_MULTIPOLYGON)
      drawLayerFeaturePolygons = layerFeatures[i].getGeometry().getPolygons()
    else if (geometryType === GEOMETRY_POLYGON)
      drawLayerFeaturePolygons = [layerFeatures[i].getGeometry()]
    // go through each polygon in feature geometry in case geometry is MultiPolygon
    for (let j = 0; j < drawLayerFeaturePolygons.length; j += 1) {
      featureWithHole = subtractPolygon({
        containerPolygon: drawLayerFeaturePolygons[j],
        holeCandidate
      })
      // set new geometry for layer's feature if it is the one hole was cut out of
      if (featureWithHole) {
        layerFeatures[i].setGeometry(featureWithHole)
        break
      }
    }
    if (featureWithHole) break
  }
  return featureWithHole
}

export default drawHoleInLayer
